<template>
  <BaseModal ref='modal' title="Bedrag afpunten">
    <FormulateForm
      v-model="values"
      name="payAdvanceForm"
      @submit="submit"
    >
      <FormulateInput
        type="number"
        name="amount"
        label="Bedrag"
        placeholder="Bedrag"
        validation="bail|required|validateAmountGtZero|validateAmountLtBalance"
        :validation-rules="{ validateAmountGtZero, validateAmountLtBalance}"
        :validation-messages="{
          validateAmountGtZero: 'Het bedrag moet groter of gelijk zijn aan 0.',
          validateAmountLtBalance: 'Het bedrag moet kleiner of gelijk zijn aan het openstaande bedrag.'
        }"
        step=".01"
        min="0"
      />
      <FormulateInput
        type="submit"
        title="Afpunten"
        outer-class="tw-my-4 tw-float-right"
      >
        Afpunten
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal } from '@/modalMessages'
import { payAdvance } from '@/services/transactions'

export default {
  name: 'PayAdvanceModal',
  data () {
    return {
      advance: {},
      values: {}
    }
  },
  methods: {
    show (advance) {
      this.advance = advance
      this.values = {
        amount: advance.balance
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    validateAmountGtZero ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) >= 0
    },
    validateAmountLtBalance ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) <= parseFloat(this.advance.balance)
    },
    async submit (data) {
      try {
        const response = await payAdvance(this.advance.id, data)
        this.hide()
        successModal('De betaling werd geregistreerd.')
        this.$emit('paid', this.advance.property)
        return response
      } catch (error) {
        this.$formulate.handle(error, 'payAdvanceForm')
      }
    }
  }
}
</script>
