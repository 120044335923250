<template>
  <div v-if="entity">
    <div class="tw-flex">
    <router-link
      :to="{name: returnPage }"
      title="Ga terug"
      class="!tw-text-tg-color hover:tw-opacity-80 tw-text-base"
    >
      <i class="fal fa-arrow-left tw-mr-2" />
    </router-link>
    <h3>Nieuw eigenaarsrapport</h3>
    </div>
    <div>
      <div>
        <div class="tw-mt-8">
          <h4>Eigenaars</h4>
          <FormulateInput
            v-model="selectedOwners"
            type="checkbox"
            :options="ownerOptions"
          />
          <div>
            <div>
              <div class="tw-flex tw-flex-wrap tw-gap-4 tw-mb-4">
                <div>
                  <FormulateInput
                    v-model="reportType"
                    type="select"
                    name="reportType"
                    label="Soort rapport"
                    placeholder="Selecteer rapport"
                    :options="[{value: 'period', label: 'Tussentijds rapport'}, {value: 'end', label: 'Eindrapport'}]"
                    value="period"
                    outer-class="tw-m-0 tw-col-span-2"
                  />
                  <FormulateInput
                    v-model="postal"
                    type="checkbox"
                    label="Brief toevoegen"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tw-mb-2 tw-w-full md:tw-w-1/2">
            <FormulateInput
              type="text"
              label="Extra uitleg"
              v-model="comments"
              placeholder="Hier kan je optioneel extra uitleg kwijt die wordt opgenomen in het rapport."
            />
            <div v-if="!generating" class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-4">
              <button
                type="button"
                :disabled="!selectedOwners.length"
                class="btn btn-success"
                @click="fetchData"
              >
                <template v-if="fetchingData" >
                  <i class="fal fa-spinner-third fa-spin tw-mr-4" /> Gegevens worden geladen ...
                </template>
                <template v-else>
                  <i class="fa fa-eye" /> 1. Preview van gegevens
                </template>
              </button>
              <button
                type="button"
                :disabled="!selectedOwners.length || !previewed"
                class="btn btn-success"
                @click="generateOwnerReports"
              >
                <i class="fa fa-print" /> 2. {{ selectedOwners.length > 1 ? "PDF's" : "PDF" }} genereren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="generationFinished">
      <hr class="tw-my-4 tw-w-2/3">
      <div v-for="ownerReport in generatedOwnerReports" :key="ownerReport.id" class="tw-grid tw-mb-2 tw-mt-4 tw-w-2/3">
        <div class="tw-flex tw-gap-x-2">
          <div>
            <a
              :href="ownerReport.url"
              target="_blank"
            >
              Eigenaarsrapport voor {{ ownerReport.owner_display_name }} (bekijken/downloaden)
            </a>
          </div>
          <template v-if="!ownerReport.deliveryType && !ownerReport.beingProcessed">
            <button
              type="button"
              @click="markOwnerReportAsDelivered(ownerReport)"
              class="btn btn-default btn-xs"
            >
              <i class="tw-mr-1 fa fa-file" /> Bezorgd (in persoon/per post)
            </button>
            <button
              type="button"
              @click="sendViaEmail(ownerReport)"
              class="btn btn-default btn-xs"
              :disabled="!ownerReport.owner_email"
              :title="!ownerReport.owner_email ? 'Eigenaar heeft geen e-mailadres' : ''"
            >
              <i class="fa fa-at" /> Nu versturen via e-mail {{ !ownerReport.owner_email ? '(geen e-mailadres)' : ''}}
            </button>
          </template>
          <template v-else-if="ownerReport.beingProcessed">
            <i class="fal fa-spinner-third fa-spin" /> Eigenaarsrapport wordt verwerkt ...
          </template>
          <template v-else>
            <span class="tw-font-bold tw-text-green-500">{{ ownerReport.deliveryType === 'email' ? 'Verstuurd via e-mail' : 'Bezorgd (in persoon/via post)' }}</span>
          </template>
        </div>
      </div>
    </template>
    <div v-else-if="generating">
      <i
        class="fal fa-spinner-third fa-spin tw-text-xl tw-mr-4"
      />
      {{ selectedOwners.length > 1 ? 'Rapporten worden gegenereerd ...' : 'Rapport wordt gegenereerd ...' }}
      <span v-if="selectedOwners.length > 1">({{ generatedOwnerReports.length }}/{{ selectedOwners.length }})</span>
    </div>
    <BaseModal  ref="modal" title="Beknopte inhoud van eigenaarsrapport" max-width="tw-max-w-lg">
      <div v-if="ownerReportDataPreview">
        <h2>Kerncijfers</h2>
        <div v-for="(figureData, index) in ownerReportDataPreview.figures" :key="index">
          <strong>{{ figureData.value }}</strong> {{ figureData.label }}
        </div>
        <h2>Pubacties</h2>
        <p
          v-for="pubAction in ownerReportDataPreview.pub_actions"
          :key="pubAction.id"
        >
          <strong>
            {{ pubAction.name }} ({{ pubAction.start_date }})
          </strong>
          <br>
          {{pubAction.description }}
        </p>
        <h2>Kandidatencontact</h2>
        <OwnerReportCandidates
          title="Kandidaten die geïnteresseerd zijn en al een bezoek brachten"
          :candidates="ownerReportDataPreview.leads_interested_visited"
        />
        <OwnerReportCandidates
          title="Kandidaten die geïnteresseerd zijn, maar (nog) geen bezoek brachten"
          :candidates="ownerReportDataPreview.leads_interested_unvisited"
        />
        <OwnerReportCandidates
          title="Kandidaten die niet langer geïnteresseerd zijn, maar wel een bezoek brachten"
          :candidates="ownerReportDataPreview.leads_uninterested_visited"
        />
        <OwnerReportCandidates
          title="Kandidaten die niet langer geïnteresseerd zijn en geen bezoek brachten"
          :candidates="ownerReportDataPreview.leads_uninterested_unvisited"
        />
        <BaseButton label="Inhoud gelezen en goedgekeurd" color="success" @click="hide" />
      </div>
    </BaseModal>
  </div>
</template>

<script>

import StoreIntegration from '@/store/integration'
import { errorModal } from '@/modalMessages'
import { createPropertyOwnerReport, createProjectOwnerReport, getPropertyOwnerReportData, getProjectOwnerReportData, sendPropertyOwnerReportViaEmail, sendProjectOwnerReportViaEmail } from '@/services/ownerReports'
import { getPropertyOwners } from '@/services/properties'
import { getProjectOwners } from '@/services/projects'
import OwnerReportCandidates from '@/components/properties/OwnerReportCandidates'
import { createDocument, pollGutenborg } from '@/services/gutenborgService'
import { poll, USER_LANG_OPTIONS } from '@/utils/helpers'
import { createLog } from '@/services/apiService'

export default {
  name: 'EntityOwnerReportForm',
  components: {
    OwnerReportCandidates
  },
  mixins: [StoreIntegration],
  constants: { USER_LANG_OPTIONS },
  data () {
    return {
      owners: [],
      reportType: 'period',
      language: 'nl',
      previewed: false,
      reportId: null,
      selectedOwners: [],
      comments: '',
      postal: false,
      ownerReportDataPerLanguage: {
        nl: null,
        fr: null,
        en: null
      },
      ownerReportDataPreview: false,
      ownerReportGenerationJobs: [],
      generatedOwnerReports: [],
      generating: false,
      generationFinished: false,
      fetchingData: false
    }
  },
  computed: {
    entity () {
      const payload = this.$route.params.id
      if (this.$route.meta.entity_type === 'property') return this.getFromStore('properties/getPropertyById', payload, 'properties/loadProperty', payload)
      return this.getFromStore('properties/getProjectById', payload, 'properties/loadProject', payload)
    },
    entityType () {
      return this.$route.meta.entity_type
    },
    languagesOfSelectedOwners () {
      const languagesForSelectedContacts = []
      this.selectedOwners.forEach(ownerId => {
        const owner = this.owners.filter(owner => {
          return owner.id === parseInt(ownerId)
        })
        const language = owner[0].language
        languagesForSelectedContacts.indexOf(language) === -1 && languagesForSelectedContacts.push(language)
      })
      return languagesForSelectedContacts
    },
    returnPage () {
      return this.entityType === 'property'
        ? 'PropertyCommunication'
        : 'ProjectCommunication'
    },
    ownerOptions () {
      return this.owners.map(owner => {
        return { value: owner.id, label: `${owner.display_name} (taal: ${USER_LANG_OPTIONS[owner.language]})` }
      })
    }
  },
  created () {
    this.loadOwners()
  },
  methods: {
    hide () {
      this.$refs.modal.hide()
    },
    showData () {
      this.ownerReportDataPreview = this.ownerReportDataPerLanguage.nl || this.ownerReportDataPerLanguage.fr || this.ownerReportDataPerLanguage.en
      this.$refs.modal.show()
    },
    async loadOwners () {
      try {
        const response = this.entityType === 'property'
          ? await getPropertyOwners(this.entity.id)
          : await getProjectOwners(this.entity.id)
        this.owners = response.data
        this.owners.forEach(owner => {
          this.selectedOwners.push(owner.id)
        })
        return response
      } catch (error) {
        errorModal('Eigenaars konden niet worden geladen.')
        console.error(error)
      }
    },
    ownersSelected (event) {
      if (event.target.checked) {
        this.selectedOwners.push(event.target.value)
      } else {
        this.selectedOwners.splice(this.selectedOwners.indexOf(event.target.value), 1)
      }
    },

    async fetchData () {
      try {
        this.fetchingData = true
        const languagesForSelectedContacts = []
        this.selectedOwners.forEach(ownerId => {
          const owner = this.owners.filter(o => {
            return o.id === parseInt(ownerId)
          })
          const language = owner[0].language
          languagesForSelectedContacts.indexOf(language) === -1 && languagesForSelectedContacts.push(language)
        })
        const basePayload = {
          id: this.entity.id,
          params: {
            language: 'nl',
            type: this.reportType
          }
        }
        const promises = []
        languagesForSelectedContacts.forEach(language => {
          basePayload.params.language = language
          this.entityType === 'property'
            ? promises.push(getPropertyOwnerReportData(basePayload))
            : promises.push(getProjectOwnerReportData(basePayload))
        })
        const responses = await Promise.all(promises)
        responses.forEach(response => {
          this.$set(this.ownerReportDataPerLanguage, response.data?.language, response.data)
        })
        this.fetchingData = false
        this.showData()
        this.previewed = true
        return responses
      } catch (error) {
        console.error(error)
        errorModal('Inhoud van eigenaarsrapport kon niet worden geladen.')
      }
    },

    getOwnerReportPayload (mergeData) {
      return {
        merge_data: mergeData,
        template_key: `${this.entityType}_owner_report_type`,
        config: {
          type: 'source',
          destination: 'temp',
          language: mergeData.language
        }
      }
    },

    async startOwnerReportGenerationJob (ownerId, filledOutData) {
      const contactData = this.owners.filter(owner => {
        return owner.id === parseInt(ownerId)
      })[0]
      const contactDisplayName = contactData.type === 'B' ? contactData.company_name : `${contactData.first_name} ${contactData.last_name}`
      const ownerData =
      {
        contact: {
          display_name: contactDisplayName,
          street: contactData.street,
          number: contactData.number,
          box: contactData.box,
          city: contactData.city,
          zip_code: contactData.zip_code
        }
      }
      const reportData = this.ownerReportDataPerLanguage[contactData.language]
      const mergeData = { ...filledOutData, ...reportData, ...ownerData }
      const payload = this.getOwnerReportPayload(mergeData)
      const response = await createDocument(payload)
      if (response.data) {
        const payload = {
          jobUuid: response.data.uuid,
          owner: contactData.id,
          owner_display_name: contactData.display_name,
          owner_email: contactData.email,
          comments: this.comments,
          type: this.reportType
        }
        this.ownerReportGenerationJobs.push(payload)
      }
    },

    async pollOwnerReportGenerationJobs () {
      for (const report of this.ownerReportGenerationJobs) {
        const pollResult = await poll(report.jobUuid, pollGutenborg, 1000)
        if (pollResult.url) {
          report.url = pollResult.url
          this.generatedOwnerReports.push(report)
        }
      }
    },

    async generateOwnerReports () {
      try {
        this.generating = true
        this.generatedOwnerReports = []
        this.generationFinished = false
        const filledOutData = {
          type: this.reportType,
          comments: this.comments,
          postal: this.postal
        }
        for (const ownerId of this.selectedOwners) {
          await this.startOwnerReportGenerationJob(ownerId, filledOutData)
        }
        await this.pollOwnerReportGenerationJobs()

        const payload = {
          action_id: 18, // Owner report generation id
          content_object_id: this.$route.params.id,
          content_object_class: this.entityType
        }
        await createLog(payload)

        this.generationFinished = true
        this.generating = false
      } catch (error) {
        errorModal('Eigenaarsrapport kon niet worden gegenereerd.')
      } finally {
        this.generating = false
      }
    },

    async createOwnerReport (deliveryType, data) {
      const payload = {
        owner: data.owner,
        url: data.url,
        comments: this.comments,
        type: this.reportType,
        delivery_type: deliveryType
      }
      const response = this.entityType === 'property'
        ? await createPropertyOwnerReport(this.entity.id, payload)
        : await createProjectOwnerReport(this.entity.id, payload)

      return response
    },

    async markOwnerReportAsDelivered (data) {
      try {
        const ownerId = data.owner
        const index = this.generatedOwnerReports.map(e => e.owner).indexOf(ownerId)
        data.beingProcessed = true
        this.generatedOwnerReports.splice(index, 1, data)

        const response = await this.createOwnerReport('physical_document', data)

        data.deliveryType = 'physical_document'
        data.beingProcessed = false
        this.generatedOwnerReports.splice(index, 1, data)

        return response
      } catch (error) {
        console.error(error)
        errorModal('Eigenaarsrapport kon niet worden opgeslagen.')
      }
    },

    async doEmailSend (ownerReportId) {
      try {
        const response = this.entityType === 'property'
          ? await sendPropertyOwnerReportViaEmail(this.entity.id, ownerReportId)
          : await sendProjectOwnerReportViaEmail(this.entity.id, ownerReportId)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Eigenaarsrapport kon niet worden verstuurd.')
      }
    },

    async sendViaEmail (data) {
      try {
        const ownerId = data.owner
        const index = this.generatedOwnerReports.map(ownerReport => ownerReport.owner).indexOf(ownerId)

        data.beingProcessed = true
        this.generatedOwnerReports.splice(index, 1, data)

        const response = await this.createOwnerReport('email', data)
        const ownerReportId = response.data?.id
        const mailResponse = await this.doEmailSend(ownerReportId)
        data.deliveryType = 'email'
        data.beingProcessed = false
        this.generatedOwnerReports.splice(index, 1, data)

        return mailResponse
      } catch (error) {
        console.error(error)
        errorModal('Eigenaarsrapport kon niet verstuurd worden via e-mail.')
      }
    }
  }
}
</script>
