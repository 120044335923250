function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.entity.categories),function(category,categoryIndex){return [(category)?_c('div',{key:category.id,staticClass:"tw-my-2 tw-bg-white"},[_c('div',{staticClass:"tw-mx-2 tw-flex tw-justify-between tw-items-baseline"},[_c('div',[(_vm.showCategoryName)?_c('h2',{staticClass:"tw-mt-2"},[_vm._v(_vm._s(category.name))]):_vm._e()]),_c('div',{staticClass:"tw-group tw-relative tw--mb-4"},[_c('button',{staticClass:"tw-px-1 tw-rounded hover:tw-bg-gray-e9",attrs:{"type":"button","title":"Toevoegen nieuwe taak"},on:{"click":function($event){return _vm.showCreateCustomTodo(category.id, { categoryIndex: categoryIndex })}}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"tw-px-1 tw-rounded hover:tw-bg-gray-e9",attrs:{"type":"button","title":"Meer acties"},on:{"click":function($event){return _vm.toggleActionsMenu(category.id)}}},[_c('i',{staticClass:"fas fa-ellipsis-h"})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionsMenu[category.id]),expression:"actionsMenu[category.id]"}],class:[
              'tw-absolute tw-text-right tw-bg-white',
              'tw-rounded tw-right-0 tw-whitespace-nowrap tw-overflow-hidden tw-z-40 tw-shadow-card'
            ]},[_vm._l((_vm.MARK_ALL_OPTIONS),function(ref){
            var label = ref.label;
            var action = ref.action;
            var onlyVisible = ref.onlyVisible;
return _c('li',{key:((category.id) + "_" + action),staticClass:"hover:tw-bg-gray-e9"},[_c('button',{staticClass:"tw-px-2 tw-py-1 tw-text-tg-color",attrs:{"type":"button","title":label},on:{"click":function($event){return _vm.markAll(categoryIndex, action, onlyVisible)}}},[_vm._v(" "+_vm._s(label)+" ")])])}),_c('li',{staticClass:"hover:tw-bg-gray-e9"},[_c('button',{staticClass:"tw-px-2 tw-py-1 tw-text-tg-color",attrs:{"type":"button","title":"Openstaande items versturen via e-mail"},on:{"click":function($event){return _vm.sendChecklistNotification(_vm.entity, category.id)}}},[_vm._v(" Openstaande items versturen via e-mail ")])])],2)])]),_c('div',{staticClass:"tw-p-2 tw-pt-1 tw-grid md:tw-grid-cols-3 tw-gap-2 tw-bg-white"},_vm._l((category.todos),function(todos,column){return _c('div',{key:column,staticClass:"md:tw-h-full"},[_c('h3',{staticClass:"tw-block md:tw-hidden tw-mt-0"},[_vm._v(_vm._s(_vm.CHECKLIST_COLUMN_TITLES[column]))]),_c('div',{staticClass:"tw-px-2 tw-py-1 tw-bg-success tw-bg-opacity-10 tw-shadow-sm tw-min-h-[60px] md:tw-h-full",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop($event, column)}}},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},_vm._l((todos),function(ref,todoIndex){
            var file_type = ref.file_type;
            var rest = objectWithoutProperties( ref, ["file_type"] );
            var todo = rest;
return _c('div',{key:todo.id,class:[
                  { 'tw-pointer-events-none': _vm.updatingTodo[todo.id] },
                  { 'tw-hidden': !_vm.showNa && !!todo.date_na },
                  { 'tw-hidden': _vm.showOnlyAgent && parseInt(todo.user) === 1 },
                  { 'tw-hidden': _vm.showOnlySalesSupport && parseInt(todo.user) === 0 },
                  'tw-my-2 tw-p-2 tw-bg-white tw-shadow-card'
                ],attrs:{"draggable":!_vm.updatingTodo[todo.id]},on:{"dragstart":function($event){return _vm.onDragStart($event, todo, column, { todoIndex: todoIndex, categoryIndex: categoryIndex })}}},[_c('details',{class:{ 'tw-animate-pulse': _vm.updatingTodo[todo.id] },attrs:{"open":_vm.expanded}},[_c('summary',{staticClass:"tw-cursor-pointer tw-relative",attrs:{"title":todo.description || todo.title}},[_c('div',{staticClass:"tw-inline-flex tw-justify-between tw-gap-2 tw-items-baseline tw-w-full tw-max-w-[93%] xl:tw-max-w-[96%] 2xl:tw-max-w-[97%]"},[_c('h4',{class:[
                          'tw-m-0',
                          { 'tw-text-gray-cc': !!todo.date_na }
                        ]},[_vm._v(" "+_vm._s(todo.title)+" ")]),_c('button',{staticClass:"btn btn-xs btn-default",attrs:{"type":"button","title":"Bewerken"},on:{"click":function($event){return _vm.showEdit({ todo: todo, column: column, categoryIndex: categoryIndex, todoIndex: todoIndex })}}},[_c('i',{staticClass:"far fa-pencil"})])]),(todo.date_expiration && !todo.date_finished)?_c('Tooltip',{class:[
                        (new Date(todo.date_expiration) - new Date()) > 172800000 ? 'tw-bg-warning' : 'tw-bg-danger',
                        'tw-absolute tw--top-3 tw--left-3 tw-px-0.5 tw-rounded-full tw-text-white tw-text-xs tw-shadow-sm'
                      ],scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" Vervaldatum: "+_vm._s(_vm._f("date-day")(todo.date_expiration))+" ")]},proxy:true}],null,true)},[_c('i',{staticClass:"fas fa-clock"})]):_vm._e()],1),_c('div',{staticClass:"tw-pl-2"},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-justify-between tw-gap-2 tw-w-full tw-mt-1"},[_c('div',{staticClass:"tw-flex-shrink"},[_c('div',{staticClass:"tw-my-2 tw-flex tw-flex-row tw-flex-wrap tw-gap-2"},[_c('button',{key:((todo.id) + "_nvt"),class:[
                              { 'tw-text-white tw-bg-success': !!todo.date_na },
                              'tw-px-2 tw-rounded-full tw-border'
                            ],attrs:{"type":"button"},on:{"click":function($event){return _vm.markNa({ todo: todo, column: column, categoryIndex: categoryIndex, todoIndex: todoIndex })}}},[_vm._v(" N.v.t. ")]),(todo.user === null && !todo.order_type)?_c('button',{staticClass:"action tw-rounded-full tw-bg-danger tw-px-2 tw-py-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteTodo({ todo: todo, categoryIndex: categoryIndex, column: column, todoIndex: todoIndex })}}},[_vm._v(" Verwijderen ")]):_vm._l(({ 0: 'Makelaar', 1: 'Sales support' }),function(label,user){return _c('button',{key:((todo.id) + "_" + label),class:[
                                { 'tw-text-white tw-bg-success': todo.user == user },
                                'tw-px-2 tw-rounded-full tw-border'
                              ],attrs:{"type":"button"},on:{"click":function($event){return _vm.changeUser({ todo: todo, categoryIndex: categoryIndex, column: column, todoIndex: todoIndex }, { user: user })}}},[_vm._v(" "+_vm._s(label)+" ")])}),(todo.order_type)?_c('button',{staticClass:"action tw-rounded-full tw-bg-warning tw-px-2 tw-py-0",attrs:{"type":"button","disabled":todo.date_started && !['legal', 'vip', 'asbestos'].includes(todo.order_type)},on:{"click":function($event){return _vm.openOrderModal({ todo: todo, column: column, categoryIndex: categoryIndex, todoIndex: todoIndex })}}},[_c('i',{staticClass:"far fa-shopping-cart tw-mr-1"}),_vm._v(" Bestellen ")]):_vm._e()],2),(todo.order_status)?_c('div',{staticClass:"tw-my-2 tw-items-start"},[_c('span',{staticClass:"tw-rounded-full tw-border tw-px-2 tw-py-0 tw-bg-gray-100"},[_c('strong',[_vm._v("Status: "+_vm._s(todo.order_status))])])]):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-2 tw-items-start"},[(todo.date_started)?_c('span',{staticClass:"pill"},[_vm._v(" Aangevraagd: "+_vm._s(_vm._f("date-day")(todo.date_started))+" ")]):_vm._e(),(todo.date_expiration)?_c('span',{staticClass:"pill"},[_vm._v(" Vervaldatum: "+_vm._s(_vm._f("date-day")(todo.date_expiration))+" ")]):_vm._e(),(todo.date_finished)?_c('span',{staticClass:"pill"},[_vm._v(" Afgewerkt: "+_vm._s(_vm._f("date-day")(todo.date_finished))+" ")]):_vm._e()])]),(file_type)?_c('FileDropzone',{staticClass:"tw-flex-grow tw-mr-2 2xl:tw-mr-1.5 2xl:tw-max-w-[200px] tw-w-full",attrs:{"url":_vm.fileUploadUrl,"is-entity-file-upload":true},on:{"file-uploaded":function($event){return _vm.fileUploaded(
                            $event,
                            { file_type: file_type },
                            todo,
                            { prevColumn: column, newColumn: 'done' },
                            { categoryIndex: categoryIndex, todoIndex: todoIndex }
                          )}},scopedSlots:_vm._u([{key:"droparea",fn:function(ref){
                          var loading = ref.loading;
return [_c('div',{class:[
                              'tw-px-4 tw-py-2 tw-w-full tw-h-full tw-grid tw-place-items-center tw-border tw-border-dashed tw-border-tg-color',
                              { 'tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100': !loading }
                            ]},[_c('i',{class:[
                                'fal fa-2x tw-pointer-events-none',
                                loading ? 'fa-spinner-third fa-spin' : 'fa-file-upload'
                              ]})])]}}],null,true)}):_vm._e()],1),(todo.notes)?_c('p',{staticClass:"tw-my-2 tw-px-1 tw-italic tw-whitespace-pre-wrap"},[_vm._v(_vm._s(todo.notes))]):_vm._e()])])])}),0)],1)])}),0)]):_vm._e()]}),_c('BaseModal',{ref:"editCreateTodo",attrs:{"title":_vm.newTask ? 'Toevoegen nieuwe taak' : 'Bewerken',"max-width":"tw-max-w-sm"}},[_c('FormulateForm',{attrs:{"name":"editCreateTodo","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":function($event){(_vm.newTask ? _vm.createCustomTodo : _vm.editTodo)($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var isLoading = ref.isLoading;
return [(_vm.newTask || _vm.edit.user === null)?_c('FormulateInput',{attrs:{"type":"text","name":"title","label":"Titel","placeholder":"Titel","validation":"required:trim","outer-class":['tw-mt-0']}}):_vm._e(),(_vm.newTask)?_c('FormulateInput',{attrs:{"type":"select","name":"order_type","label":"Order type","options":_vm.customTaskOptions,"outer-class":['tw-mt-0']}}):_vm._e(),_c('FormulateInput',{attrs:{"type":"date","name":"date_expiration","label":"Vervaldatum","placeholder":"YYYY-MM-DD","validation":"bail|optional|date:YYYY-MM-DD","outer-class":['tw-mt-0']}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"notes","label":"Commentaar"}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-2"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}})],1),_c('LegalOrderModal',{ref:"legalOrderModal",attrs:{"todo":_vm.orderTodo,"property":_vm.entity,"baseRegistersData":_vm.entity.base_registers_data},on:{"orderPlaced":_vm.orderHandler,"orderResend":_vm.orderResendHandler}}),_c('CadastralOrderModal',{ref:"cadastralOrderModal",attrs:{"todo":_vm.orderTodo},on:{"orderPlaced":_vm.orderHandler,"orderResend":_vm.orderResendHandler}}),_c('CertificationOrderModal',{ref:"certificationOrderModal",attrs:{"entity":_vm.entity},on:{"completed":_vm.orderCompleted}}),_c('SmoovedOrderModal',{ref:"smoovedOrderModal",attrs:{"entity":_vm.entity,"todo":_vm.orderTodo},on:{"completed":_vm.orderCompleted}}),(_vm.entityType === 'property')?_c('VipOrderModal',{ref:"vipOrderModal",attrs:{"property":_vm.entity,"todo":_vm.orderTodo},on:{"completed":_vm.orderCompleted}}):_vm._e(),(_vm.entityType === 'property')?_c('EccaOrderModal',{ref:"eccaOrderModal",attrs:{"property":_vm.entity,"todo":_vm.orderTodo},on:{"completed":_vm.orderCompleted}}):_vm._e(),_c('CreateCostModal',{ref:"costCreateModal",attrs:{"entity":_vm.entity}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }