<template>
  <div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="contact"
      name="contactsCreate"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <component
        :is="modal ? 'BaseModal' : 'div'"
        ref="contactCreateModal"
        title="Nieuw contact aanmaken"
        max-width="tw-max-w-2xl"
      >
        <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
          <div>
            <FormulateInput
              type="select"
              name="type"
              label="Contacttype"
              :options="{ P: 'Persoon', B: 'Bedrijf' }"
              value="P"
            />
            <div v-if="contact.type === 'B'">
              <FormulateInput
                type="text"
                name="company_name"
                label="Bedrijfsnaam"
                validation="required"
                :show-required-label="true"
              />
              <FormulateInput
                type="select"
                name="company_type"
                label="Bedrijfstype"
                placeholder="Selecteer het bedrijfstype"
                :options="COMPANY_TYPES"
                :show-required-label="contact.type === 'B'"
                :validation="contact.type === 'B' ? 'required' : ''"
              />
            </div>
            <div v-if="contact.type === 'P'" class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="text"
                name="first_name"
                label="Voornaam"
                validation="required"
                :show-required-label="true"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="last_name"
                label="Naam"
                validation="required"
                :show-required-label="true"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="gender"
                label="Geslacht"
                placeholder="Selecteer geslacht"
                :options="{ M: 'Man', F: 'Vrouw', U: 'Onbekend' }"
                value="U"
                outer-class="tw-m-0 tw-col-span-2"
              />
            </div>
            <FormulateInput
              type="autocomplete"
              auto-complete-type="street"
              name="street"
              label="Straat"
              @callback="streetSelected"
            />
            <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="text"
                name="number"
                label="Huisnummer"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="box"
                label="Bus"
                outer-class="tw-m-0"
              />
            </div>
            <FormulateInput
              type="autocomplete"
              auto-complete-type="city"
              name="city"
              label="Plaats"
              placeholder="Zoek op postcode of plaatsnaam"
            />
          </div>

          <div>
            <FormulateInput
              type="select"
              name="language"
              label="Voorkeurstaal"
              placeholder="Selecteer taal"
              :options="USER_LANG_OPTIONS"
              value="nl"
            />
            <FormulateInput
              type="text"
              name="vat"
              label="Ondernemingsnummer"
              placeholder="BE 0###.###.###"
              help="Vb.: BE 0682.871.387"
              :show-required-label="contact.type === 'B'"
              :validation="contact.type === 'P' ? 'bail|optional|validVatNumber' : 'bail|required|validVatNumber'"
            />
            <FormulateInput
              type="checkbox"
              name="is_vat_liable"
              label="Btw-plichtig"
            />
            <fieldset class="tw-m-0 tw-px-4 tw-border-gray-cc tw-rounded">
              <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
                Contactmethodes
                <span v-if="contact.type === 'P'" class="input-error">(verplicht)</span>
              </legend>
              <FormulateInput
                v-model.trim="contact.email"
                type="email"
                name="email"
                label="E-mail"
                validation="bail|optional|email"
                @input="resetDuplicateContacts"
              />
              <FormulateInput
                type="dial_code_tel"
                name="mobile"
                label="Gsm"
                validation="bail|optional|phone|noDuplicateDialCode"
                @input="resetDuplicateContacts"
              />
              <FormulateInput
                type="dial_code_tel"
                name="phone"
                label="Telefoon"
                validation="bail|optional|phone"
                @input="resetDuplicateContacts"
              />
              <span v-if="noContactMethod" v-text="noContactMethod" class="input-error"/>
            </fieldset>
          </div>
        </div>

        <span v-show="noContactMethod" v-text="noContactMethod" class="tw-mt-4 tw-block input-error tw-text-right" />
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading || noContactMethod || duplicateContacts.results.length >= 1"
          :outer-class="[{ 'tw-float-right': modal}]"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Contact opslaan
        </FormulateInput>

        <div v-if="duplicateContacts.results.length">
          <div class="tw-mx-4">
            Opgepast: er zijn dubbele contacten gevonden op basis van de contactgegevens die je hebt ingevuld hebt. Een nieuw contact kan niet worden aangemaakt.
          </div>
          <DataTable
            :headers="headers"
            v-bind="duplicateContacts"
            class="tw-m-4"
          >
            <template #item-id="{ item }">
              <component
                :is="redirect ? 'router-link' : 'button'"
                :to="{ name: 'ContactDetails', params: { id: item.id } }"
                type="button"
                class="link tw-font-semibold"
                @click="contactClick(item)"
              >
                {{ item.display_name }}
              </component>
            </template>
          </DataTable>
        </div>
      </component>
    </FormulateForm>
  </div>
</template>

<script>
import Modal from '@/components/iam/Modal'
import DataTable from '@/components/DataTable'
import { questionModal } from '@/modalMessages'

import COMPANY_TYPES from './company_types'
import { formatContact, USER_LANG_OPTIONS } from '@/utils/helpers'
import { createContact, getExactDuplicateContacts } from '@/services/contacts'

export default {
  name: 'ContactCreateModal',
  constants: { USER_LANG_OPTIONS, COMPANY_TYPES },
  components: {
    Modal,
    DataTable
  },
  props: {
    modal: {
      type: Boolean,
      default: true
    },
    values: {
      type: Object
    },
    redirect: {
      // Takes user to the Contact's page after creation.
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      contact: {},
      duplicateContacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    noContactMethod () {
      // It is mandatory only for contact type "Person"
      const { email, phone, mobile, type } = this.contact
      return !(email || phone || mobile) && type === 'P'
        ? 'Er is minimaal één e-mailadres, telefoonnummer of mobiel telefoonnummer vereist.'
        : false
    },
    headers () {
      return [
        { value: 'id', text: 'Naam' },
        { value: 'email', text: 'E-mail' },
        { value: 'mobile', text: 'Gsm-nummer' },
        { value: 'phone', text: 'Telefoonnummer' }
      ]
    }
  },
  watch: {
    values: {
      immediate: true,
      handler (value) {
        if (value) this.contact = Object.assign({}, this.contact, value)
      }
    }
  },
  methods: {
    show () {
      this.$refs.contactCreateModal.show()
    },
    hide () {
      this.$refs.contactCreateModal.hide()
    },
    streetSelected (street) {
      if (street?.city) this.contact.city = street.city
      if (street?.number) this.contact.number = street.number
    },
    async validateContact (email, mobile, phone, type) {
      // If no email, mobile or phone is entered (possible for company contacts), skip validation.
      if (!email && !mobile && !phone) return true

      const exactDuplicateContacts = await getExactDuplicateContacts({ params: { email, mobile, phone, type } })
      this.duplicateContacts = exactDuplicateContacts.data
      if (exactDuplicateContacts.data?.results?.length) return false
      return true
    },
    async submit (data) {
      try {
        const payload = formatContact(data)
        const proceed = await this.validateContact(payload.email, payload.mobile, payload.phone, payload.type)

        if (proceed) {
          const response = await createContact(payload)
          const contact = response.data
          if (this.modal) this.hide()
          if (this.redirect) this.$router.push({ name: 'ContactDetails', params: { id: contact.id } })
          this.$emit('success', contact)
        }

        return proceed
      } catch (error) {
        if (error.response?.data?.code?.[0] === 'INVALID_VAT_VIES') {
          const result = await questionModal("Volgens de officiële VIES-databank is dit geen bestaand btw-nummer. Gelieve het btw-nummer dubbel te controleren. Klik op 'Ja' om verder te gaan.", '')
          if (!result.value) return
          this.contact.skip_vat_check = true
          return await this.submit(this.contact)
        }
        console.error(error)
        this.$formulate.handle(error, 'contactsCreate')
      }
    },
    resetDuplicateContacts () {
      if (this.duplicateContacts.results.length) {
        this.duplicateContacts = {
          count: null,
          next: null,
          previous: null,
          results: []
        }
      }
    },
    contactClick (contact) {
      this.$emit('success', contact)
      this.hide()
      return contact
    }
  }
}
</script>
