<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Energiegegevens
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="propertyEnergyForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <h2>Algemeen</h2>
      <div class="tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
        <FormulateInput
          name="aen_home"
          label="BEN-woning"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="conformity_test_certificate"
          label="Conformiteit keuringsattest"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="electricity_meter"
          label="Elektriciteitsmeter"
          placeholder="Type elektriciteitsmeter"
          :options="{
            0: 'Onbekend',
            1: 'Niet van toepassing',
            2: 'Gemeenschappelijk',
            3: 'Individueel'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="electricity_meter_type"
          label="Type elektriciteitsmeter"
          placeholder="Selecteer type elektriciteitsmeter"
          :options="{
            0: 'Onbekend',
            1: 'Nog te plaatsen',
            2: 'Enkelvoudige meter',
            3: 'Dag- en nachtmeter',
            4: 'Uitsluitend nachtmeter',
            5: 'Slimme meter'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="fuel_oil_certificate"
          label="Attest voor stookolietank verkregen"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="fuel_oil_tank"
          label="Stookolietank"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="fuel_oil_tank_capacity"
          label="Inhoud stookolietank (liter)"
          placeholder="Inhoud stookolietank (liter)"
          v-bind="NUMBER_INPUT_POSITIVE"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="fuel_oil_tank_type"
          label="Stookolietanktype"
          placeholder="Stookolietanktype"
          :options="{
            0: 'Niet van toepassing',
            1: 'Bovengronds in gebruik',
            2: 'Bovengronds niet in gebruik',
            3: 'Ondergronds in gebruik',
            4: 'Ondergronds niet in gebruik'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="glazing"
          label="Beglazing"
          placeholder="Type beglazing"
          :options="{
            0: 'Onbekend',
            1: 'Enkel glas',
            2: 'Dubbel glas',
            3: 'Driedubbel glas'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="green_energy_certificates"
          label="Groenstroomcertificaten"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="battery"
          label="Thuisbatterij"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="heat_pump"
          label="Warmtepomp"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="heating_boiler"
          label="Verwarmingsketel"
          placeholder="Type ketel"
          :options="{
            0: 'Onbekend',
            1: 'Niet van toepassing',
            2: 'Gemeenschappelijk',
            3: 'Individueel'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="heating_source"
          label="Verwarmingsbron"
          placeholder="Verwarmingsbron"
          :options="{
            0: 'Onbekend',
            1: 'Stookolie',
            2: 'Steenkool',
            3: 'Elektriciteit',
            4: 'Aardgas',
            5: 'Accumulatie',
            6: 'Gaskachels',
            7: 'Propaangas',
            8: 'Centraal verluchtingssysteem',
            9: 'Hout',
            10: 'Pellets',
            11: 'Geothermie',
            12: 'Warmtenet',
            13: 'Warmtepomp lucht-lucht',
            14: 'Warmtepomp lucht-water',
            15: 'Zonneboiler'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="heating_type"
          label="Verwarmingstype"
          placeholder="Selecteer verwarmingstype"
          :options="{
            0: 'Onbekend',
            1: 'Radiatoren',
            2: 'Vloerverwarming',
            3: 'Centrale verwarming',
            4: 'Accumulatoren',
            5: 'Houtkachels',
            6: 'Pelletkachels',
            7: 'Airconditioning',
            8: 'Gaskachel',
            9: 'Elektrische accumulatie',
            10: 'Elektrische verwarming',
            11: 'Centraal verluchtingssysteem',
            12: 'Gashaard'

          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="translatable"
          name="information_test_certificate"
          label="Keuringsattest - extra informatie"
          outer-class="md:tw-col-span-2 xl:tw-col-span-4"
        />
        <FormulateInput
          type="select"
          name="natural_gas_meter"
          label="Meter voor aardgas"
          placeholder="Meter voor aardgas"
          :options="{
            0: 'Onbekend',
            1: 'Niet van toepassing',
            2: 'Gemeenschappelijk',
            3: 'Individueel'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="solar_panels"
          label="Zonnepanelen (kw)"
          placeholder="Zonnepanelen (kw)"
          v-bind="NUMBER_INPUT_POSITIVE"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="solar_water_heater"
          label="Zonneboiler (liter)"
          placeholder="Zonneboiler (liter)"
          v-bind="NUMBER_INPUT_POSITIVE"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="test_cetificate"
          label="Keuringsattest"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="select"
          name="water_meter"
          label="Watermeter"
          placeholder="Type watermeter"
          :options="{
            0: 'Onbekend',
            1: 'Niet van toepassing',
            2: 'Gemeenschappelijk',
            3: 'Individueel'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="windmill"
          label="Windmolen"
          v-bind="YES_NO_UNKNOWN"
          outer-class="tw-m-0"
        />
      </div>

      <h2>Meters</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
        <FormulateInput
          type="text"
          name="electricity_meter_number"
          label="EAN/Meternummer elektriciteit"
          placeholder="EAN/Meternummer elektriciteit"
          element-class="tw-flex tw-flex-row tw-gap-1"
          outer-class="tw-m-0"
        >
          <div v-if="!loading" slot="suffix" class="tw-min-w-[100px]">
            <FormulateInput
              type="file"
              name="electricity_meter_document"
              :uploader="uploadElectricityMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>

        <FormulateInput
          type="text"
          name="gas_meter_number"
          label="EAN/Meternummer gas"
          placeholder="EAN/Meternummer gas"
          element-class="tw-flex tw-flex-row tw-gap-1"
          outer-class="tw-m-0"
        >
          <div v-if="!loading" slot="suffix" class="tw-min-w-[100px]">
            <FormulateInput
              type="file"
              name="gas_meter_document"
              :uploader="uploadGasMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>
        <FormulateInput
          type="text"
          name="water_meter_number"
          label="Meternummer water"
          placeholder="Meternummer water"
          element-class="tw-flex tw-flex-row tw-gap-1"
          outer-class="tw-m-0"
        >
          <div v-if="!loading" slot="suffix" class="tw-min-w-[100px]">
            <FormulateInput
              type="file"
              name="water_meter_document"
              :uploader="uploadWaterMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>
      </div>

      <FormulateErrors />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { YES_NO_UNKNOWN, NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import {
  getPropertyEnergy,
  updatePropertyEnergy,
  uploadFile,
  createPropertyFile
} from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PropertyEnergyEdit',
  constants: {
    YES_NO_UNKNOWN,
    NUMBER_INPUT_POSITIVE
  },
  data () {
    return {
      loading: false,
      values: {}
    }
  },
  computed: {
    backRoute () {
      return this.$route.meta.backRoute
    },
    propertyId () {
      return this.$route.params.id
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    goBack () {
      this.$router.push(this.backRoute)
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    async loadData () {
      try {
        this.loading = true
        const response = await getPropertyEnergy(this.propertyId)
        const {
          electricity_meter_document,
          gas_meter_document,
          water_meter_document,
          ...values
        } = response.data
        this.values = { ...values }
        this.$set(this.values, 'gas_meter_document', this.parseFileObj(gas_meter_document))
        this.$set(this.values, 'water_meter_document', this.parseFileObj(water_meter_document))
        this.$set(this.values, 'electricity_meter_document', this.parseFileObj(electricity_meter_document))
        this.loading = false
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyEnergyForm')
        errorModal('Fout bij het laden van energiegegevens, probeer het opnieuw.')
      }
    },
    async uploadElectricityMeterDoc (file, progress) {
      const FILE_TYPE_ELECTRICITY_METER_DOCUMENT = 64
      const response = await this.uploadFile(file, progress, FILE_TYPE_ELECTRICITY_METER_DOCUMENT)
      return response
    },
    async uploadWaterMeterDoc (file, progress) {
      const FILE_TYPE_WATER_METER_DOCUMENT = 66
      const response = await this.uploadFile(file, progress, FILE_TYPE_WATER_METER_DOCUMENT)
      return response
    },
    async uploadGasMeterDoc (file, progress) {
      const FILE_TYPE_GAS_METER_DOCUMENT = 66
      const response = await this.uploadFile(file, progress, FILE_TYPE_GAS_METER_DOCUMENT)
      return response
    },
    async uploadFile (file, progress, file_type) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const payload = { key, filename, file_type }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        this.$formulate.handle(error, 'propertyEnergyForm')
      }
    },
    async submit (data) {
      try {
        const response = await updatePropertyEnergy(this.propertyId, data)
        successModal('De wijzigingen aan de energiegegevens zijn succesvol opgeslagen')
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyEnergyForm')
        errorModal('Er ging iets fout bij het wijzigen van energiegegevens van dit pand. Gelieve nogmaals te proberen.')
      }
    }
  }
}
</script>
