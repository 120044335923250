var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("formulate-input-element formulate-input-element--" + (_vm.context.type)),attrs:{"data-type":_vm.context.type}},[_c('Multiselect',_vm._b({attrs:{"options":_vm.tags,"loading":_vm.searching,"taggable":_vm.taggable,"multiple":true,"searchable":true,"internal-search":false,"label":"name","select-label":"","selected-label":"","deselect-label":"Verwijderen"},on:{"open":function($event){return _vm.searchTags('')},"search-change":_vm.searchTags,"tag":function($event){return _vm.context.rootEmit('tag', $event)},"select":function($event){return _vm.context.rootEmit('select', $event)}},scopedSlots:_vm._u([(!_vm.showTagsOnClose)?{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',[_vm._v(" Zoek naar een tag ")]):_vm._e()]}}:null,{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"tag",style:(("\n          background: " + (option.color) + ";\n          color: " + (_vm.getTagTextColor(option.color)) + ";\n        ")),attrs:{"aria-label":"selection"}},[_vm._v(" "+_vm._s(option.name)+" "),(_vm.allowRemove)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return remove(option)}}},[_c('i',{staticClass:"far fa-times"})]):_vm._e()])]}},{key:"option",fn:function(ref){
        var option = ref.option;
        var search = ref.search;
return [(option.name)?_c('span',{staticClass:"tag",style:(("\n          background: " + (option.color) + ";\n          color: " + (_vm.getTagTextColor(option.color)) + ";\n        ")),attrs:{"aria-label":"option"}},[_vm._v(" "+_vm._s(option.name)+" ")]):(_vm.taggable)?_c('span',{staticClass:"tw-text-darken-success"},[_c('i',{staticClass:"far fa-plus tw-mr-1"}),_vm._v(" Tag maken voor '"+_vm._s(search)+"' ")]):_vm._e()]}},{key:"noResult",fn:function(){return [_vm._v("Geen tag gevonden")]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v("Geen tag gevonden")]},proxy:true}],null,true),model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}},'Multiselect',_vm.context.attributes,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }