<template>
  <div class="tw-my-12 tw-mx-auto tw-max-w-2xl">
    <Calendar
      v-model="visitDate"
      :inline="true"
      :highlighted="highlightedDates"
      :loading="loadingHighlightedDates"
      @selected="getTimeSlots"
    />
    <div v-if="visitDate" ref="slots" class="tw-p-5 lg:tw-p-7.5 tw-my-7.5 tw-shadow-card">
      <span class="tw-text-base tw-text-gray-cc">
        {{ weekday }}
      </span>
      <h3 class="tw-text-2xl">
        {{ formattedDate }}
      </h3>
      <PropertyVisitCreateSlots
        :visit-date="visitDate"
        :has-at-least-one-booking="hasAtLeastOneBooking"
        @updateTimeSlots="updateTimeSlots"
      />
      <transition name="fade" mode="out-in">
        <div v-if="loadingTimeSlots" class="tw-text-center">
          <i class="far fa-spinner-third fa-spin tw-text-xl" />
        </div>
        <template v-else-if="timeSlots.length">
          <PropertyVisitSlotsList
            :time-slots="timeSlots"
            @updateTimeSlots="updateTimeSlots"
          />
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import { getPropertyAvailableDates, getPropertyAvailableSlots } from '@/services/calendarService'
import { formatDateForLocale } from '@/utils/helpers'
import { compareAsc } from 'date-fns'

export default {
  name: 'PropertyVisitSlots',
  components: {
    Calendar: () => import(/* webpackChunkName: "Calendar" */ '@/components/Calendar'),
    PropertyVisitSlotsList: () =>
      import(/* webpackChunkName: "PropertyVisitSlotsList" */ '@/components/properties/PropertyVisitSlotsList'),
    PropertyVisitCreateSlots: () =>
      import(
        /* webpackChunkName: "PropertyVisitCreateSlots" */ '@/components/properties/PropertyVisitCreateSlots'
      )
  },
  data () {
    return {
      visitDate: null,
      highlightedDates: {
        dates: []
      },
      loadingTimeSlots: false,
      timeSlots: [],
      loadingHighlightedDates: false
    }
  },
  computed: {
    formattedDate () {
      return this.visitDate ? formatDateForLocale(this.visitDate) : ''
    },
    weekday () {
      return this.visitDate
        ? this.visitDate.toLocaleDateString('nl', {
          weekday: 'long'
        })
        : ''
    },
    hasAtLeastOneBooking () {
      return this.timeSlots.some(slot => { return slot.booking_set })
    }
  },
  watch: {
    visitDate (newVal) {
      if (newVal) {
        this.$nextTick(function () {
          if (this.$refs.slots) this.$refs.slots.scrollIntoView()
        })
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const date = new Date()
      date.setHours(0, 0, 0, 0)
      this.visitDate = date // Set the visit date to 00:00:00:00 of the current day to make sure validTime validation works right.

      this.getHighlightedDates()
      this.getTimeSlots(this.visitDate)
    },
    getHighlightedDates () {
      this.loadingHighlightedDates = true
      this.highlightedDates.dates = []

      const date = new Date()
      const startAt = date.toISOString()

      getPropertyAvailableDates(
        this.propertyId,
        startAt
      ).then(response => {
        response.data.forEach(dateObj => {
          this.highlightedDates.dates.push(new Date(dateObj.date))
        })
        this.loadingHighlightedDates = false
      })
    },
    getTimeSlots (event) {
      this.loadingTimeSlots = true

      const date =
        compareAsc(new Date(), new Date(event)) > 0
          ? new Date()
          : new Date(event)

      const startAt = date.toISOString()

      date.setHours(23)
      date.setMinutes(59)

      const endAt = date.toISOString()

      getPropertyAvailableSlots(
        this.propertyId,
        startAt,
        endAt
      )
        .then(response => {
          this.timeSlots = response.data.results
        })
        .finally(() => {
          this.loadingTimeSlots = false
        })
    },
    updateTimeSlots () {
      this.getTimeSlots(this.visitDate)
      this.getHighlightedDates()
    }
  }
}
</script>
