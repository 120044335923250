var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":_vm.title,"max-width":"tw-max-w-4xl"}},[_c('WizardRentalAgreementStep1',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 1),expression:"activeStep === 1"}],on:{"finished":_vm.moveForward}},'WizardRentalAgreementStep1',{
      propertyId: _vm.propertyId,
      initialValues: _vm.initialValues,
      thirdPartyAccounts: _vm.thirdPartyAccounts,
      rentalTerms: _vm.rentalTerms
    },false)),_c('WizardRentalAgreementStep2',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 2),expression:"activeStep === 2"}],on:{"finished":_vm.moveForward,"go-back":_vm.goBack}},'WizardRentalAgreementStep2',{
      propertyId: _vm.propertyId,
      stepValues: _vm.stepValues
    },false)),_c('WizardRentalAgreementStep3',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 3),expression:"activeStep === 3"}],on:{"finished":_vm.hide,"go-back":_vm.goBack}},'WizardRentalAgreementStep3',{
      propertyId: _vm.propertyId,
      initialValues: _vm.initialValues,
      stepValues: _vm.stepValues
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }