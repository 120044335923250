<template>
  <BaseModal
    ref="modal"
    :title="title"
    max-width="tw-max-w-7xl"
  >
    <BaseModal ref="mapModal" title="Zoeken op kaart" max-width="tw-max-w-[85%]">
      <SearchMap
        ref="map"
        :geo-search="geoSearch"
      />
      <FormulateInput
        type="button"
        title="Opslaan"
        outer-class="tw-float-right tw-my-4"
        @click="saveMap"
      >
        <i class="fas tw-mr-1 fa-save" />
        Opslaan
      </FormulateInput>
    </BaseModal>
    <FormulateForm
      #default="{ isLoading }"
      v-model="hmodh"
      name="hmodhEditCreate"
      debounce
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-grid md:tw-grid-cols-3 tw-gap-x-6">
        <div>
          <h2 class="!tw-mt-0">Algemeen</h2>
          <FormulateInput
            type="multiselect"
            name="types"
            label="Pandtypes"
            placeholder="Selecteer types"
            debounce
            :options="PROPERTY_TYPES"
            validation="required"
          />
          <FormulateInput
            type="checkbox"
            name="niches"
            label="Niches"
            :options="{
              1: 'Woonvastgoed',
              2: 'Bedrijfsvastgoed'
            }"
            validation="required"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
          />
          <FormulateInput
            type="select"
            name="source"
            label="Merk"
            :options="[
              { value: 1, label: 'Dewaele' },
              { value: 2, label: 'Turner', disabled: true },
              { value: 4, label: 'Vanderbuild', disabled: true }
            ]"
          />
          <FormulateInput
            type="select"
            name="status"
            label="Status"
            :options="{
              2: 'Te koop',
              3: 'Te huur',
              4: 'Over te nemen'
            }"
          />
          <fieldset class="fieldset-split-range">
            <legend>Prijs</legend>
            <FormulateInput
              type="number"
              name="price_min"
              min="0"
              placeholder="Min"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="number"
              name="price_max"
              min="0"
              placeholder="Max"
              outer-class="tw-m-0"
            />
          </fieldset>
          <FormulateInput
            type="select"
            name="sales_concept"
            label="Concept"
            :options="{
              0: 'Alles',
              1: 'Klassiek',
              2: 'Presale',
              3: 'Countdown',
              4: 'Rent To Buy'
            }"
          />
          <fieldset class="tw-border-none tw-p-0 tw-m-0">
            <legend class="formulate-label">
              Filter enkel op
            </legend>
            <div class="tw-my-1 tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
              <FormulateInput
                type="checkbox"
                name="is_investment"
                label="Investeringsvastgoed"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="checkbox"
                name="is_newly_built"
                label="Nieuwbouw"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="checkbox"
                name="is_luxury"
                label="Luxevastgoed"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="checkbox"
                name="has_development_potential"
                label="Ontwikkelingspotentieel"
                outer-class="tw-m-0"
              />
            </div>
          </fieldset>
          <FormulateInput
            type="checkbox"
            name="epc_labels"
            label="Energielabel"
            :options="EPC_LABELS"
            :input-class="['tw-my-1 tw-w-6']"
            :element-class="['tw-flex tw-flex-row tw-gap-1 tw-items-center']"
          />

          <FormulateInput
            type="button"
            title="Zoeken op kaart"
            :input-class="['tw-bg-transparent tw-text-success tw-border tw-border-success tw-w-full hover:tw-bg-success hover:tw-text-white']"
            outer-class="tw-m-0"
            @click="showMap"
          >
            <i class="fa fa-globe" />
            Zoeken op kaart
            <template #help>
              <div v-if="geosearchActive" class="tw-text-center tw-mt-1">
                Polygonen op kaart geselecteerd
              </div>
            </template>
          </FormulateInput>

          <FormulateInput
            v-model="hmodh.zipcodes"
            type="autocomplete"
            auto-complete-type="city"
            :multiple="true"
            :unpack-children="getChildCities"
            name="zipcodes"
            label="Steden"
            placeholder="Zoek op postcode of plaatsnaam"
          />
          <FormulateInput
            type="checkbox"
            name="manual"
            label="Manueel"
          />
          <FormulateInput
            type="select"
            name="on_ground_floor"
            label="Gelijkvloers"
            :options="ALL_YES_NO"
          />
          <FormulateInput
            type="select"
            name="ready_to_go"
            label="Instapklaar"
            :options="ALL_YES_NO"
          />
          <fieldset class="fieldset-split-range">
            <legend>Parkeerplaatsen</legend>
            <FormulateInput
              type="number"
              name="parking_spots_min"
              min="0"
              placeholder="Min"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="number"
              name="parking_spots_max"
              min="0"
              placeholder="Max"
              outer-class="tw-m-0"
            />
          </fieldset>
          <FormulateInput
            type="textarea"
            name="comment_internal"
            label="Interne informatie"
          />
          <FormulateInput
            type="textarea"
            name="comment_by_contact"
            disabled
            label="Commentaar contact via klantenportaal (read-only)"
          />
        </div>

        <!-- Second column -->
        <div>
          <h2 class="!tw-mt-0">Investeringsvastgoed</h2>
          <fieldset class="fieldset-split-range">
            <legend>Rendement</legend>
            <FormulateInput
              type="number"
              name="return_on_investment_min"
              min="0"
              placeholder="Min"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="number"
              name="return_on_investment_max"
              min="0"
              placeholder="Max"
              outer-class="tw-m-0"
            />
          </fieldset>
          <FormulateInput
            type="checkbox"
            name="is_rented"
            label="Momenteel verhuurd"
          />
          <FormulateInput
            type="select"
            name="sale_with_shares"
            label="Verkoop onder aandelen"
            :options="{
              1: 'Ja',
              2: 'Nee',
              5: 'Beide'
            }"
          />

          <div v-if="showOtherInputs || showResidentialInputs">
            <h2 class="!tw-mt-0">Woonvastgoed</h2>
            <fieldset class="fieldset-split-range">
              <legend>Slaapkamers</legend>
              <FormulateInput
                type="number"
                name="bedrooms_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="bedrooms_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Badkamers</legend>
              <FormulateInput
                type="number"
                name="bathrooms_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="bathrooms_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Bouwjaar</legend>
              <FormulateInput
                type="number"
                name="build_year_from"
                min="0"
                placeholder="Van"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="build_year_till"
                min="0"
                placeholder="Tot"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Renovatiejaar</legend>
              <FormulateInput
                type="number"
                name="renovation_year_from"
                min="0"
                placeholder="Van"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="renovation_year_till"
                min="0"
                placeholder="Tot"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Perceel opp.</legend>
              <FormulateInput
                type="number"
                name="surface_plot_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_plot_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Bewoonbare opp.</legend>
              <FormulateInput
                type="number"
                name="surface_livable_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_livable_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Oppervlakte terras</legend>
              <FormulateInput
                type="number"
                name="terrace_area_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="terrace_area_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Verdieping</legend>
              <FormulateInput
                type="number"
                name="floor_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="floor_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <FormulateInput
                type="select"
                name="garden"
                label="Tuin"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="terrace"
                label="Terras"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
            </fieldset>
            <FormulateInput
              type="select"
              name="garage"
              label="Garage"
              :options="ALL_YES_NO"
              outer-class="tw-m-0"
            />
            <fieldset class="fieldset-split-range">
              <FormulateInput
                type="select"
                name="swimming_pool"
                label="Zwembad"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="pets_allowed"
                label="Huisdieren"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <FormulateInput
                type="select"
                name="sea_view"
                label="Zeezicht"
                :options="{
                  ...ALL_YES_NO,
                  3: 'Ja, maar geen zijdelings'
                }"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="elevator"
                label="Lift"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <FormulateInput
                type="select"
                name="bike_storage"
                label="Fietsenberging"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="furnished"
                label="Gemeubeld"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
            </fieldset>
            <FormulateInput
              type="checkbox"
              label="Bebouwing"
              name="building_types"
              :options="{
                1: 'Open',
                2: 'Halfopen',
                3: 'Gesloten'
              }"
              :input-class="['tw-my-1']"
              :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
            />
          </div>
        </div>

        <!-- Third column -->
        <div>
          <div v-if="showOtherInputs || showCommercialInputs">
            <h2 class="!tw-mt-0">Bedrijfsvastgoed</h2>
            <fieldset class="fieldset-split-range">
              <legend>Perceel opp.</legend>
              <FormulateInput
                type="number"
                name="surface_plot_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_plot_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Handels opp.</legend>
              <FormulateInput
                type="number"
                name="surface_trading_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_trading_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Vrije hoogte</legend>
              <FormulateInput
                type="number"
                name="free_height_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="free_height_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Laadkaaien</legend>
              <FormulateInput
                type="number"
                name="loading_docks_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="loading_docks_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>kVA</legend>
              <FormulateInput
                type="number"
                name="kva_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="kva_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Bruikbare kantoor ruimte</legend>
              <FormulateInput
                type="number"
                name="usable_office_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="usable_office_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <legend>Bruikbare opslagplaats</legend>
              <FormulateInput
                type="number"
                name="usable_storage_min"
                min="0"
                placeholder="Min"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="usable_storage_max"
                min="0"
                placeholder="Max"
                outer-class="tw-m-0"
              />
            </fieldset>
            <fieldset class="fieldset-split-range">
              <FormulateInput
                type="select"
                name="light_street"
                label="Lichtstraat"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="rolling_bridge"
                label="Rolbrug"
                :options="ALL_YES_NO"
                outer-class="tw-m-0"
              />
            </fieldset>
          </div>
        </div>
      </div>

      <FormulateErrors class="tw-text-right" />
      <FormulateInput
        type="submit"
        :title="createHmodhFromBreakLead ? 'E-mail versturen' : 'Opslaan'"
        :disabled="isLoading"
        outer-class="tw-float-right tw-mb-4"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        {{ createHmodhFromBreakLead ? 'E-mail versturen' : 'Opslaan' }}
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import SearchMap from '@/components/contacts/hmodh/SearchMap'

import {
  PROPERTY_TYPES,
  EPC_LABELS,
  RESIDENTIAL_PROPERTY_TYPES,
  COMMERCIAL_PROPERTY_TYPES,
  OTHER_PROPERTY_TYPES
} from '@/forms/selectOptions'

import { successModal, errorModal } from '@/modalMessages'

import { createHmodh, updateHmodh } from '@/services/hmodh'
import { deleteLog, updateLog, getCityById } from '@/services/apiService'

export default {
  name: 'HmodhEditCreate',
  components: {
    SearchMap
  },
  constants: {
    EPC_LABELS,
    PROPERTY_TYPES,
    ALL_YES_NO: {
      0: 'Alles',
      1: 'Ja',
      2: 'Nee'
    }
  },
  props: {
    contact: {
      type: Object
    }
  },
  data () {
    return {
      hmodh: {},
      isMapVisible: false,
      breakLeadId: null,
      hmodhCreated: false,
      createHmodhFromBreakLead: false,
      logId: null
    }
  },
  computed: {
    geoSearch () {
      return this.hmodh?.geo_search || []
    },
    geosearchActive () {
      return this.hmodh.geo_search?.length
    },
    showResidentialInputs () {
      return (this.hmodh.types || []).some(type => RESIDENTIAL_PROPERTY_TYPES.includes(type))
    },
    showCommercialInputs () {
      return (this.hmodh.types || []).some(type => COMMERCIAL_PROPERTY_TYPES.includes(type))
    },
    showOtherInputs () {
      return (this.hmodh.types || []).some(type => OTHER_PROPERTY_TYPES.includes(type))
    },
    title () {
      const edit = this.hmodh?.id
      if (edit) return 'Zoekopdracht bewerken'
      if (this.createHmodhFromBreakLead) return 'Zoekopdracht aanmaken voor lead'
      return 'Nieuwe zoekopdracht aanmaken'
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.hmodh = {}
      this.$refs.modal.hide()
    },
    hideMap () {
      this.$refs.map.clear()
      this.$refs.mapModal.hide()
    },

    saveMap () {
      const cities = this.$refs.map.getCities()
      const coordinates = this.$refs.map.getCoordinates()

      this.$set(this.hmodh, 'zipcodes', [...cities])
      this.$set(this.hmodh, 'geo_search', coordinates)
      this.hideMap()
    },

    async getChildCities (value) {
      // Type 1 is a regular city and should be returned as is
      // Other types should return the child cities
      if (value.type === 1) return [value]
      const response = await getCityById(value.id)
      return response.data?.children
    },
    async showMap () {
      /** Shows the Hmodh map.
       *
       * The editor holds all child cities, but the map holds parent cities. For example:
       * Dikmuide + deelgemeenten is a parent city and Beerst or Leke is a child city.
       *
       * Whenever we open the map we fetch all child cities specified in the editor and determine their parent cities
       * if any. We then populate the map with parent cities + the cities that do not have a parent city.
       *
       * */
      const childCities = this.hmodh.zipcodes
      // If the parent's city is a custom type (e.g. province) then use the city itself
      // Else use the parent city
      const parentCities = childCities
        .map(childCity => childCity.parent?.type === 3 || !childCity.parent ? childCity : childCity.parent)
        .reduce((parentCities, parentCity) => {
          const parentCityIds = parentCities.map(city => city.id)
          if (parentCityIds.includes(parentCity.id)) {
            return parentCities
          } else {
            return parentCities.concat([parentCity])
          }
        }, [])

      this.$refs.mapModal.show()
      this.$nextTick(() => {
        parentCities.forEach(city => {
          const citiesOnMap = this.$refs.map.cities
          const citiesOnMapIds = citiesOnMap.map(city => city.id)
          if (!citiesOnMapIds.includes(city.id)) {
            this.$refs.map.cities.push(city)
            this.$refs.map.selectedFeatures.push(city.id)
          }
        })
        this.$refs.map.render()
      })
    },

    async updateLeadBreakHmodhCreationLog () {
      if (!this.createHmodhFromBreakLead || this.hmodhCreated) return
      if (!this.logId) return
      const response = await updateLog(this.logId, { meta: { hmodh_mail_sent: 1 } })
      return response
    },

    /* Handles hmodh update */
    async updateHmodhHandler (data) {
      try {
        const hmodh = { ...data }
        delete hmodh.created_by
        hmodh.contact = hmodh.contact?.id
        if (hmodh.zipcodes) hmodh.zipcodes = hmodh.zipcodes.map(zipcode => zipcode.id)

        const response = await updateHmodh(hmodh.id, hmodh)
        this.$emit('hmodhUpdated', response)
        successModal('De HMODH is bijgewerkt.')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het aanpassen van de Hou me op de hoogte, gelieve opnieuw te proberen')
      }
    },

    /* Handles hmodh creation */
    async createHmodhHandler (data) {
      try {
        const hmodh = { ...data }

        hmodh.contact = this.contact?.id
        if (hmodh.zipcodes) hmodh.zipcodes = hmodh.zipcodes.map(zipcode => zipcode.id)

        if (this.createHmodhFromBreakLead) hmodh.is_hmodh_for_match_break = true

        const response = await createHmodh(hmodh)
        await this.updateLeadBreakHmodhCreationLog()
        this.hmodhCreated = true

        this.$emit('hmodhCreated', response)

        let successMessage = 'HMODH is gemaakt.'
        if (this.createHmodhFromBreakLead) {
          successMessage = 'Er werd een uitnodiging gestuurd naar de lead om de aangemaakte HMODH te activeren.'
        }
        successModal(successMessage)
        return response
      } catch (error) {
        console.error(error)
        // If there's an error upon creating the HMODH,
        // delete the entire lead break log so that collaborators aren't punished for possible bugs (?)
        if (this.logId) {
          await deleteLog(this.logId)
        }
        errorModal(`Kan Hou me op de hoogte niet aanmaken, gelieve opnieuw te proberen: ${error}`)
        throw error
      }
    },

    /* Triggers the update or creation of a hmodh */
    async submit (data) {
      const edit = this.hmodh.id
      const response = edit
        ? await this.updateHmodhHandler(data)
        : await this.createHmodhHandler(data)
      this.hide()
      return response
    }
  }
}
</script>

<style scoped>
.fieldset-split-range {
  @apply tw-my-4;
}
</style>
