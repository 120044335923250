<template>
  <div>
    <PageHeader title="Overzicht te ontvangen voorschotten en huurgelden" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-0.5 tw-mx-auto">
      <div class="tw-mb-8 tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-shadow-lg">
        <FormulateForm
          v-model="values"
        >
          <div class="tw-grid tw-grid-cols-3 tw-col-start-1 tw-col-span-3">
            <FormulateInput
              type="autocomplete"
              auto-complete-type="user-offices"
              :multiple="false"
              name="office"
              label="Kantoor"
              placeholder="Selecteer kantoor"
              outer-class="tw-col-start-1 tw-col-end-1 tw-col-span-3 tw-my-2"
              @input="getThirdPartyAccounts"
            />
            <FormulateInput
              v-show="values.office"
              type="select"
              :options="thirdPartyAccounts"
              label="Derdenrekening"
              placeholder="Selecteer derdenrekening"
              name="account"
              class="tw-col-start-1 tw-col-end-1 tw-col-span-3 tw-my-2"
              @input="getAdvances"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
    <div class="tw-px-2.5 tw-pb-16 tw-mx-auto">
      <DataTable
        :loading="loading"
        :headers="headers"
        :can-select="false"
        :infinite-scroll="true"
        v-bind="advances"
        @infiniteLoad="infiniteLoad"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-justify-between">
            <div class="tw-flex tw-flex-wrap tw-gap-y-1 tw-justify-end">
              <div class="tw-flex">
                <FormulateInput
                  v-if="values.account"
                  v-model="showPayedAdvances"
                  type="toggle"
                  name="status"
                  label="Toon betaalde voorschotten"
                  outer-class="tw-my-0 tw-mr-4 tw-self-center"
                  @input="togglePayedAdvances"
                />
              </div>
            </div>
          </div>
        </template>
        <template #item-transaction_number="{ item }">
          <a
            v-if="item.transaction_data.id"
            target="_blank"
            :href="`/transaction/view/${item.transaction_data.id}`"
          >
            {{ item.transaction_data.transaction_number }}
          </a>
          <span v-else>
            -
          </span>
        </template>
        <template #item-amount="{ item }">
          {{ currency(item.amount) }}
        </template>
        <template #item-balance="{ item }">
          {{ currency(item.balance) }}
        </template>
        <template #item-action="{ item }">
          <span v-if="item.balance > 0">
            <button
              type="button"
              title="Afpunten"
              class="action tw-bg-success"
              @click="payAdvance(item)"
            >
              Afpunten
            </button>
            <button
              type="button"
              title="Bewerk voorschot"
              class="action tw-bg-warning"
              @click="showAdvanceEditModal(item)"
            >
              <i class="fa fa-pencil" />
            </button>
          </span>
          <span
            v-else
            class="tw-bg-primary tw-px-2 tw-py-1 tw-text-white tw-text-center tw-rounded-sm tw-text-sm tw-inline-block"
          >
            Betaald
          </span>
        </template>
      </DataTable>
      <PayAdvanceModal ref="payAdvanceModal" @paid="handleAdvancePaid"/>
      <UpdateSalesAdvanceModal
        ref="updateSalesAdvanceModal"
        :third-party-accounts="thirdPartyAccounts"
        @updated="handleAdvanceUpdate"
      />
      <UpdateRentalAdvanceModal
        ref="updateRentalAdvanceModal"
        :third-party-accounts="thirdPartyAccounts"
        @updated="handleAdvanceUpdate"
      />
    </div>
    <StartFinalInvoiceModal ref="startFinalInvoiceModal" />
  </div>
</template>

<script>
import { currency } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'

import DataTable from '@/components/DataTable'
import { errorModal } from '@/modalMessages'
import { getAdvances, getThirdPartyAccounts } from '@/services/transactions'
import PayAdvanceModal from '@/components/transactions/PayAdvanceModal.vue'
import { checkTransactionReadyForFinalInvoice } from '@/utils/transactions'
import StartFinalInvoiceModal from '@/components/transactions/StartFinalInvoiceModal.vue'
import UpdateSalesAdvanceModal from '@/components/transactions/UpdateSalesAdvanceModal.vue'
import UpdateRentalAdvanceModal from '@/components/transactions/UpdateRentalAdvanceModal.vue'

export default {
  name: 'TransactionAdvances',
  components: {
    UpdateSalesAdvanceModal,
    UpdateRentalAdvanceModal,
    StartFinalInvoiceModal,
    PayAdvanceModal,
    PageHeader,
    DataTable
  },
  props: {},
  data () {
    return {
      values: {},
      thirdPartyAccounts: [],
      advances: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      loading: false,
      showPayedAdvances: false
    }
  },
  watch: {},
  computed: {
    headers () {
      return [
        { text: 'Pand', value: 'property_display', orderable: false },
        { text: 'Transactienummer', value: 'transaction_number', orderable: false },
        { text: 'Datum', value: 'created_on', orderable: false },
        { text: 'Te ontvangen bedrag', value: 'amount', orderable: false },
        { text: 'Saldo voorschot', value: 'balance', orderable: false },
        { text: 'Afpunten', value: 'action', orderable: false }
      ]
    }
  },
  methods: {
    currency,
    payAdvance (advance) {
      this.$refs.payAdvanceModal.show(advance)
    },
    async loadAdvances ({ payload }) {
      try {
        const response = await getAdvances(payload)
        return response.data
      } catch (error) {
        console.error(error)
        errorModal('Kan voorschotten niet laden, probeer het opnieuw.')
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.advances.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const advances = await this.loadAdvances({ payload: { url: next } })
        const results = [...this.advances.results, ...advances.results]
        this.advances = { ...advances, results }
        $infinite.loaded()
        return advances
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async getThirdPartyAccounts (office) {
      this.values.account = null
      if (!office) {
        this.thirdPartyAccounts = []
        this.advances = { count: null, next: null, previous: null, results: [] }
        return
      }
      const payload = { params: { office: office.id } }
      const response = await getThirdPartyAccounts(payload)
      this.thirdPartyAccounts = response.data.results.map(account => ({
        value: account.id,
        label: account.display_name
      }))
    },
    async getAdvances (thirdPartyAccount) {
      if (!thirdPartyAccount) return
      this.loading = true
      this.advances = await this.loadAdvances({ payload: { params: { account: thirdPartyAccount, exclude_finished_advances: !this.showPayedAdvances } } })
      this.loading = false
    },
    async handleAdvancePaid (propertyId) {
      const response = await this.getAdvances(this.values.account)
      const result = await checkTransactionReadyForFinalInvoice(propertyId)
      if (result.showModal) {
        this.$refs.startFinalInvoiceModal.show(result.modalData)
      }
      return response
    },
    async handleAdvanceUpdate () {
      return await this.getAdvances(this.values.account)
    },
    async togglePayedAdvances () {
      this.loading = true
      this.advances = await this.loadAdvances({
        payload: {
          params: {
            account: this.values.account,
            exclude_finished_advances: !this.showPayedAdvances
          }
        }
      })
      this.loading = false
    },
    showAdvanceEditModal (advance) {
      if (advance.transaction_type === 2) {
        this.$refs.updateSalesAdvanceModal.show(advance)
      } else {
        this.$refs.updateRentalAdvanceModal.show(advance)
      }
    }
  }
}
</script>

<style scoped>
</style>
