import { DOSSIER_COMMON_INPUTS, actions } from './dossier'
import { YES_NO_BOTH } from '@/forms/schemas/generators'

const schema = [
  ...DOSSIER_COMMON_INPUTS,
  {
    component: 'div',
    class: 'tw-max-w-xl',
    children: [
      {
        component: 'h2',
        children: 'Compromis'
      },
      {
        type: 'date',
        name: 'agreement_signature_date',
        label: 'Datum ondertekening',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        component: 'h2',
        children: 'Akte'
      },
      {
        type: 'date',
        name: 'deed_date',
        label: 'Datum verlijden',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD'
      },
      {
        type: 'checkbox',
        name: 'is_deed_signed',
        label: 'Akte is doorgegaan'
      },
      {
        component: 'h2',
        children: 'Overige (verkoopdossier)'
      },
      {
        name: 'sale_with_shares',
        label: 'Verkoop onder aandelen',
        outerClass: 'tw-m-0',
        ...YES_NO_BOTH
      }
    ]
  }
]

export default {
  heading: 'Dossier',
  schema,
  actions
}
