<template>
    <FormulateForm
      #default="{ isLoading }"
      v-model="form"
      name="form"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
        <FormulateInput
          type="date"
          name="termination_letter_reception_date"
          label="Datum ontvangst opzegbrief"
          placeholder="YYYY-MM-DD"
          validation="bail|optional|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          name="mandate_end_date"
          label="Datum einde opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
      </div>
      <FormulateInput
        type="select"
        name="termination_reason"
        label="Reden van opzeg"
        placeholder="Kies een reden van opzeg"
        validation="required"
        :options="terminationReasons"
      />
      <FormulateInput
        type="textarea"
        name="termination_reason_info"
        label="Reden van opzeg: extra informatie"
        :input-class="['tw-h-24']"
      />
      <FormulateInput
        type="file"
        name="termination_file"
        label="Opladen opzegbrief"
        :uploader="uploadFile"
        :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
        :file-class="['tw-h-10 !tw-mt-1']"
        element-class="formulate-input-element formulate-input-element--file"
        wrapper-class="formulate-input-wrapper"
        outer-class="formulate-input !tw-my-4"
      >
        <template #file="context">
          <BaseFileUploadDisplay
            v-bind="context"
            :show-remove-file="true"
          />
        </template>
      </FormulateInput>
      <FormulateErrors class="tw-text-right" />

    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </div>
    </FormulateForm>
</template>

<script>
import { today, parseFileObj } from '@/utils/helpers'
import {
  createPropertyFile,
  createPropertyFileFolder,
  getPropertyFileFolders,
  updateProperty,
  uploadFile
} from '@/services/properties'
import { clearTransactionPoules, updateTransaction } from '@/services/transactions'

export default {
  name: 'WizardRemovedFromPortfolioStep1',
  props: {
    property: {
      type: Object,
      required: true
    },
    propertyId: {
      type: [Number, String],
      required: true
    },
    terminationReasons: {
      type: Array,
      required: true
    }
  },
  created () {
    this.$set(this.form, 'termination_letter_reception_date', this.property.termination_letter_reception_date)
    this.$set(this.form, 'mandate_end_date', this.property.mandate_end_date || today.date)
    this.$set(this.form, 'termination_reason', this.property.termination_reason?.id)
    this.$set(this.form, 'termination_reason_info', this.property.termination_reason_info)
  },
  constants: {
    CURRENT_STEP: 1
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    async createAgreementsFolder () {
      const response = await createPropertyFileFolder(this.propertyId, { name: 'Overeenkomsten' })
      return response.data.id
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const file_type_key = 'termination_letter'
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folderResponse = await getPropertyFileFolders(this.propertyId, { query: 'Overeenkomsten' })
        const folderId = folderResponse?.data?.results?.[0]?.id
        const folder_id = folderId || await this.createAgreementsFolder()
        progress(75)
        const payload = { key, filename, file_type_key, folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        const parsedFile = parseFileObj(response.data)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'form')
      }
    },
    async submit (data) {
      try {
        const isMandateEndDateInThePast = data.mandate_end_date <= today.date
        if (isMandateEndDateInThePast) {
          // Only set the status of the property to "Removed from portfolio (15)" if the mandate_end_date is in the past or equal to today
          // Future mandate endings are processed automatically by means of the daily script run on the server
          data.status = 15
        }
        data.stage_key = 'archival'
        const propertyResponse = await updateProperty(this.propertyId, data)
        let transactionResponse = null
        let poulesResponse = null
        if (isMandateEndDateInThePast && this.property.transaction) {
          const transactionPayload = { transaction_end_status: 'T', transaction_end_date: data.mandate_end_date }
          transactionResponse = await updateTransaction(this.property.transaction.id, transactionPayload)
          poulesResponse = await clearTransactionPoules(this.property.transaction.id)
        }
        this.$emit('finished', isMandateEndDateInThePast)
        return [propertyResponse, transactionResponse, poulesResponse]
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
