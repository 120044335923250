<template>
  <span
    :class="[
      'tw-inline-flex tw-items-center tw-font-semibold tw-px-2 tw-py-1 tw-rounded tw-text-white tw-bg-gray-500',
    ]"
  >
    <span>Status: {{ statusLowerCase }} {{ isArchived ? '(gearchiveerd)' : '' }}</span>
  </span>
</template>

<script>
export default {
  name: 'PropertyStatus',
  props: {
    statusDisplay: {
      type: String,
      required: true
    },
    isArchived: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    statusLowerCase () {
      return this.statusDisplay.toLowerCase()
    }
  }
}
</script>
