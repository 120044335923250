<template>
  <div v-if="Object.values(property).length" class="row">
    <div class="col-md-12 col-lg-6">
      <PanelBox title="Algemeen">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'general' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Referentie</dt>
          <dd>{{ property.reference }}</dd>
          <dt>Niche</dt>
          <dd>{{ property.niche.name.text_nl || 'Niet ingegeven' }}</dd>
          <dt>Adres</dt>
          <dd>
            {{ property.street }} {{ property.number }}
            <span v-if="property.box">{{ property.box }}</span>
          </dd>
          <dt>Plaats</dt>
          <dd v-if="property.city">
            {{ property.city.name }} ({{ property.city.zip_code }})
          </dd>
          <dt>Gewest</dt>
          <dd>{{ property.region_display }}</dd>
          <template v-if="concept && concept.type === 6">
            <dt>Eigenaar woont op zelfde adres</dt>
            <dd>{{ property.is_owner_resident | yesno }}</dd>
          </template>
        </dl>
      </PanelBox>
      <PanelBox title="Concept">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'concept' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
          <router-link
            :to="{ name: 'PropertyConceptEdit' }"
            title="Bewerken v2"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken v2
          </router-link>
        </span>

        <dl v-if="concept" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Concept</dt>
          <dd>{{ concept.name }}</dd>
          <template v-if="concept.type === 3 && concept.amount1">
            <dt>Huurprijs Rent to buy</dt>
            <dd>{{ concept.amount1 | currency-verbose }}</dd>
          </template>
          <template v-if="concept.type === 6">
            <dt>Type pakket</dt>
            <dd>{{ property.diy_package_display }}</dd>
            <dt>Dossier ingediend door eigenaar</dt>
            <dd>{{ property.is_submitted_by_diy_owner | yesno }}</dd>
          </template>
        </dl>
        <div v-if="concept && concept.type === 6 && biddingRounds.length > 0" >
          <h3>Biedrondes</h3>
          <table class="tw-my-4 tw-w-full tw-table-auto">
            <thead>
              <tr>
                <th class="tw-w-1/3 tw-border-b tw-pb-1">Type</th>
                <th class="tw-w-1/3 tw-border-b tw-pb-1">Starttijd</th>
                <th class="tw-w-1/3 tw-border-b tw-pb-1">Eindtijd</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="biddingRound in biddingRounds" :key="biddingRound.id">
                <td class="tw-py-1">{{ biddingRound.type_display }}</td>
                <td class="tw-py-1">{{ formatDateTime(biddingRound.start) }}</td>
                <td class="tw-py-1">
                  <span :class="{ 'tw-line-through': biddingRound.extension_time }"> {{ formatDateTime(biddingRound.end) }}</span>
                  <template v-if="biddingRound.extension_time">
                    <br />
                    {{ `${formatDateTime(biddingRound.calculated_end_time)} (+${biddingRound.extension_time} minuten)` }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </PanelBox>

      <PanelBox title="Kenmerken">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'features' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Nieuwbouw <i class="far fa-globe-europe" /></dt>
          <dd>{{ property.is_newly_built | yesno }}</dd>
          <dt>Luxevastgoed <i class="far fa-globe-europe" /></dt>
          <dd>{{ property.is_luxury | yesno }}</dd>
          <dt>Ontwikkelingspotentieel <i class="far fa-globe-europe" /></dt>
          <dd>{{ property.has_development_potential | yesno }}</dd>
        </dl>
      </PanelBox>

      <PanelBox title="Dossier">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'dossier' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

          <h3>Algemeen</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Automatische communicatie aan eigenaar toestaan</dt>
            <dd>{{ property.allow_automated_communication | yesno }}</dd>
          </dl>

          <h3>Mandaat</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Datum ondertekening opdracht</dt>
            <dd>{{ formatDateForLocale(property.mandate_signature_date, false) || '-' }}</dd>
            <dt>Datum einde opdracht</dt>
            <dd>{{ formatDateForLocale(property.mandate_end_date, false) || '-' }}</dd>
            <dt>Datum ontvangst opzegbrief</dt>
            <dd>{{ formatDateForLocale(property.termination_letter_reception_date, false) || '-' }}</dd>
            <dt>Reden van opzeg</dt>
            <dd>{{ property.termination_reason ? property.termination_reason.name : '-' }}</dd>
            <dt>Reden van opzeg - extra info</dt>
            <dd>{{ property.termination_reason_info || '-' }}</dd>
          </dl>

          <template v-if="isSales">
            <h3>Compromis</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Datum ondertekening</dt>
              <dd>{{ formatDateForLocale(property.agreement_signature_date, false) || '-' }}</dd>
            </dl>
            <h3>Voorschot</h3>
            <dl v-if="advance" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Voorschot</dt>
              <dd>{{ currency(advance.amount) || '-' }}</dd>
              <dt>Nog te betalen</dt>
              <dd>{{ currency(advance.balance) || '-' }}</dd>
              <dt>Betaling voorschot op rekening</dt>
              <dd>{{ advance.account_display || '-' }}</dd>
              <dt>Afhouding ereloon van voorschot</dt>
              <dd>{{ getYesNo(property.should_deduct_sales_fee_from_advance_payment) }}</dd>
            </dl>
            <dl v-else-if="property.advance_payment_account === 2" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Voorschot</dt>
              <dd>{{ currency(property.advance_payment) || '-' }}</dd>
              <dt>Betaling voorschot op rekening</dt>
              <dd>{{ property.advance_payment_account_display || '-' }}</dd>
            </dl>
            <p v-else>Er is geen voorschot</p>
            <h3>Akte</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Datum verlijden</dt>
              <dd>{{ formatDateForLocale(property.deed_date, false) || '-' }}</dd>
              <dt>Akte is doorgegaan</dt>
              <dd>{{ property.is_deed_signed | yesno }}</dd>
            </dl>
            <h3>Overige (verkoopdossier)</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Verkoop onder aandelen <i class="far fa-globe-europe" /></dt>
              <dd>{{ property.sale_with_shares | yes_no_both }}</dd>
            </dl>
          </template>

          <template v-if="isRental">
            <h3>Verhuurovereenkomst</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Datum ondertekening</dt>
              <dd>{{ formatDateForLocale(property.agreement_signature_date, false) || '-' }}</dd>
              <dt>Type beheer</dt>
              <dd>{{ property.rental_management_type_display || '-' }}</dd>
              <dt>Duurtijd huurcontract</dt>
              <dd>{{ property.rental_term ? property.rental_term.name : '-' }}</dd>
              <dt>Huur startdatum</dt>
              <dd>{{ formatDateForLocale(property.rental_start_date, false) || '-' }}</dd>
              <dt>Huur einddatum</dt>
              <dd>{{ formatDateForLocale(property.rental_end_date, false) || '-' }}</dd>
            </dl>
          </template>
      </PanelBox>

      <PanelBox title="Comfort">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'comfort' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <dl v-if="comfort" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Aangepast voor personen met een handicap</dt>
          <dd>{{ comfort.adapted_for_the_disabled | yes_no_unknown }}</dd>
          <dt>Alarm <i class="far fa-globe-europe" /></dt>
          <dd>{{ comfort.alarm | yes_no_unknown }}</dd>
          <dt>Badkamertype</dt>
          <dd>{{ comfort.bathroom_type_display || '-' }}</dd>
          <dt>Rolluiken</dt>
          <dd>{{ comfort.blinds | yes_no_unknown }}</dd>
          <dt>Rolluiken - extra informatie</dt>
          <dd>{{ (comfort.blinds_description && comfort.blinds_description.text_nl) || '-' }}</dd>
          <dt>Distributie</dt>
          <dd>{{ comfort.distribution | yes_no_unknown }}</dd>
          <dt>Lift <i class="far fa-globe-europe" /></dt>
          <dd>{{ comfort.elevator | yes_no_unknown }}</dd>
          <dt>Bevloering</dt>
          <dd>{{ (comfort.flooring_description && comfort.flooring_description.text_nl) || '-' }}</dd>
          <dt>Gemeubeld <i class="far fa-globe-europe" /></dt>
          <dd>{{ comfort.furnished | yes_no_unknown }}</dd>
          <dt>Domotica</dt>
          <dd>{{ comfort.home_automation | yes_no_unknown }}</dd>
          <dt>Parlofoon</dt>
          <dd>{{ comfort.intercom | yes_no_unknown }}</dd>
          <dt>Aansluiting internet</dt>
          <dd>{{ comfort.internet_connection | yes_no_unknown }}</dd>
          <dt>Keukentype</dt>
          <dd>{{ comfort.kitchen_type_display }}</dd>
          <dt>Schrijnwerk buiten</dt>
          <dd>{{ comfort.outside_joinery_material_display }}</dd>
          <dt>Huisdieren toegelaten obv reglement van interne orde/huishoudelijk reglement</dt>
          <dd>{{ comfort.pets_allowed | yes_no_unknown }}</dd>
          <dt>Huisdieren toegelaten - extra informatie</dt>
          <dd>{{ (comfort.pets_allowed_description && comfort.pets_allowed_description.text_nl) || '-' }}</dd>
          <dt>Regenput</dt>
          <dd>{{ comfort.rain_pit | yes_no_unknown }}</dd>
          <dt>Inhoud regenput</dt>
          <dd>{{ comfort.rain_pit_capacity }}</dd>
          <dt>Septische put</dt>
          <dd>{{ comfort.septic_tank | yes_no_unknown }}</dd>
          <dt>Aansluiting riolering</dt>
          <dd>{{ comfort.sewerage | yes_no_unknown }}</dd>
          <dt>Aansluiting telefoonlijn</dt>
          <dd>{{ comfort.telephone_line_connection | yes_no_unknown }}</dd>
          <dt>Videofoon</dt>
          <dd>{{ comfort.videophone | yes_no_unknown }}</dd>
          <dt>Waterverzachter</dt>
          <dd>{{ comfort.water_softener | yes_no_unknown }}</dd>
          <dt>Waterput</dt>
          <dd>{{ comfort.water_well | yes_no_unknown }}</dd>
          <dt>Fietsberging</dt>
          <dd>{{ comfort.bike_storage | yes_no_unknown }}</dd>
          <dt>Ventilatiesysteem</dt>
          <dd>{{ comfort.ventilation_system_display }}</dd>
        </dl>
      </PanelBox>

      <PanelBox title="Technisch">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'technical' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <template v-if="technical">
          <h3>Oppervlaktes en afmetingen</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Perceeloppervlakte <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.surface_plot || '-' }}</dd>
            <dt>Bebouwde oppervlakte <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.surface_built || '-' }}</dd>
            <dt>Bewoonbare oppervlakte <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.surface_livable || '-' }}</dd>
            <dt>Handelsoppervlakte <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.surface_trading || '-' }}</dd>

            <dt>Perceeldiepte</dt>
            <dd>{{ technical.surface_plot_depth || '-' }}</dd>
            <dt>Perceelbreedte</dt>
            <dd>{{ technical.surface_plot_width || '-' }}</dd>
            <dt>Gevelbreedte</dt>
            <dd>{{ technical.facade_width }}</dd>
          </dl>
          <h3>Bouw- en renovatiejaar</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Bouwjaar <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.build_year || '-' }}</dd>
            <dt>Renovatiejaar <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.renovation_year || '-' }}</dd>
          </dl>
          <h3>Overige</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Bouwverplichting</dt>
            <dd>{{ technical.build_obligation | yes_no_unknown }}</dd>
            <dt>Rooilijn</dt>
            <dd>{{ technical.building_line }}</dd>
            <dt>Bebouwing <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.building_type_display || '-' }}</dd>
            <dt>Kroonlijsthoogte</dt>
            <dd>{{ technical.cornice_height }}</dd>
            <dt>Omgeving</dt>
            <dd>{{ technical.environment_display || '-' }}</dd>
            <dt>Verdieping <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.floor }}</dd>
            <dt>Aantal verdiepingen <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.number_of_floors }}</dd>
            <dt>Type parkeerplaats <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.parking_spot_type_display || '-' }}</dd>
            <dt>Aantal parkeerplaatsen <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.parking_spots }}</dd>
            <dt>Aankoop garage mogelijk </dt>
            <dd>{{ technical.is_garage_sale_possible | yesno }}</dd>
            <dt>Dakbeschrijving</dt>
            <dd v-if="technical.roof_description">
              {{ technical.roof_description.text_nl || '-' }}
            </dd>
            <dt>Dakhelling</dt>
            <dd>{{ technical.roof_slope }}</dd>
            <dt>Daktype</dt>
            <dd>{{ technical.roof_type_display || '-' }}</dd>
            <dt>Zeezicht <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.sea_view|yesnounknownsideview }}</dd>
            <dt>Staat <i class="far fa-globe-europe" /></dt>
            <dd>{{ (technical.state && technical.state.name) || '-' }}</dd>

            <dt>Gelijkvloers <i class="far fa-globe-europe" /></dt>
            <dd>{{ technical.on_ground_floor | yes_no_unknown }}</dd>
            <dt>Instapklaar</dt>
            <dd>{{ technical.ready_to_go | yes_no_unknown }}</dd>
            <dt>Oriëntatie</dt>
            <dd>{{ technical.orientation || '-' }}</dd>
          </dl>
        </template>
      </PanelBox>
    </div>
    <div class="col-md-12 col-lg-6">
      <PanelBox title="Wettelijke vermeldingen">
        <span slot="toolbar">
          <button
            title="Weergeven"
            type="button"
            class="toolbar-button tw-mr-2"
            @click="showLegalNotices"
          >
            <i class="fas fa-info-circle" /> weergeven
          </button>
          <router-link
            :to="{ name: 'PropertyLegalEdit' }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <template v-if="legal">
          <h3>Stedenbouwkundige vergunning</h3>
          <dl v-if="property.region === 1" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Status</dt>
            <dd>{{ legal.status_display }}</dd>
            <dt>Datum aanvraag</dt>
            <dd>{{ legal.request_date | date-day | default_if_none('-') }}</dd>
          </dl>

          <template v-else>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Stedenbouwkundige vergunning</dt>
              <dd>{{ legal.building_permit | yes_no_request }}</dd>
              <dt>Stedenbouwkundige vergunning - extra informatie</dt>
              <dd>{{ (legal.building_permit_info && legal.building_permit_info.text_nl) || '-' }}</dd>
            </dl>

            <h3>Stedenbouwkundige bestemming</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Type bestemming</dt>
              <dd>{{ legal.destination_type_display || '-' }}</dd>
              <dt>Type secundaire bestemming</dt>
              <dd>{{ legal.destination_type_secundairy_display || '-' }}</dd>
              <dt>Bestemming - extra informatie</dt>
              <dd>{{ (legal.destination_info && legal.destination_info.text_nl) || '-' }}</dd>
            </dl>

            <h3>Handhaving</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Bestuursdwang</dt>
              <dd>{{ legal.administrative_order | yes_no_request }}</dd>
              <dt>Minnelijke schikking</dt>
              <dd>{{ legal.amicable_settlement | yes_no_request }}</dd>
              <dt>Last onder dwangsom</dt>
              <dd>{{ legal.penalty_payment | yes_no_request }}</dd>
              <dt>Rechterlijke herstelmaatregel</dt>
              <dd>{{ legal.remedial_action | yes_no_request }}</dd>
              <dt>Dagvaardingen</dt>
              <dd>{{ legal.subpoenas | yes_no_request }}</dd>
              <dt>Handhaving - extra informatie</dt>
              <dd>{{ (legal.subpoenas_info && legal.subpoenas_info.text_nl) || '-' }}</dd>
            </dl>

            <h3>Voorkooprecht</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Voorkooprecht</dt>
              <dd>{{ legal.preemption | yes_no_request }}</dd>
              <dt>Voorkooprecht - extra informatie</dt>
              <dd>{{ (legal.preemption_description && legal.preemption_description.text_nl) || '-' }}</dd>
            </dl>

            <h3>Verkavelingsvergunning</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Verkavelingsvergunning</dt>
              <dd>{{ legal.allotment_permit | yes_no_request }}</dd>
            </dl>

            <h3>Watertoets</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <template v-if="showWaterTestOldFields">
                <dt>Effectief overstromingsgevoelig</dt>
                <dd>{{ legal.flooding_effective | yes_no_not_entered }}</dd>
                <dt>Mogelijk overstromingsgevoelig</dt>
                <dd>{{ legal.flooding_possible | yes_no_not_entered }}</dd>
              </template>
              <dt>P-scores</dt>
              <dd>
                <span v-for="(score, index) in legal.p_scores" :key="`p_score_${index}`" class="tw-block">
                  Perceelnummer {{ score.plot_number }}: {{ score.value }}
                </span>
              </dd>
              <dt>G-scores</dt>
              <dd>
                <span v-for="(score, index) in legal.g_scores" :key="`g_score_${index}`" class="tw-block">
                  Gebouw-ID {{ score.plot_number }}: {{ score.value }}
                </span>
              </dd>
              <dt>Afgebakend overstromingsgebied</dt>
              <dd>{{ legal.flooding_plain | yes_no_not_entered }}</dd>
              <dt>Afgebakende oeverzone</dt>
              <dd>{{ legal.flooding_bank | yes_no_not_entered }}</dd>
              <dt>Risicozone voor overstromingen</dt>
              <dd>{{ legal.flooding_risk | yes_no_not_entered }}</dd>
              <dt>Watertoets - extra informatie</dt>
              <dd>{{ (legal.flooding_info && legal.flooding_info.text_nl) || '-' }}</dd>
            </dl>

            <h3>Onroerend erfgoed</h3>
            <h4 class="tw-italic">Beschermd onroerend erfgoed</h4>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Beschermd monument</dt>
              <dd>{{ legal.heritage_protected_monument | yes_no_not_entered }}</dd>
              <dt>Beschermd landschap</dt>
              <dd>{{ legal.heritage_protected_area | yes_no_not_entered }}</dd>
              <dt>Beschermd cultuurhistorisch landschap</dt>
              <dd>{{ legal.heritage_protected_cultural_area | yes_no_not_entered }}</dd>
              <dt>Beschermd stadsgezicht</dt>
              <dd>{{ legal.heritage_protected_city | yes_no_not_entered }}</dd>
              <dt>Beschermd dorpsgezicht</dt>
              <dd>{{ legal.heritage_protected_village | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologische site</dt>
              <dd>{{ legal.heritage_protected_archaeological_site | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologische zone</dt>
              <dd>{{ legal.heritage_protected_archaeological_zone | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologisch monument</dt>
              <dd>{{ legal.heritage_protected_archaeological_monument | yes_no_not_entered }}</dd>
              <dt>Onroerend erfgoed - extra informatie</dt>
              <dd>{{ (legal.heritage_info && legal.heritage_info.text_nl) || '-' }}</dd>
            </dl>
            <h4 class="tw-italic">Geïnventariseerd onroerend erfgoed</h4>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Landschapsatlas</dt>
              <dd>{{ legal.heritage_landscape_atlas | yes_no_not_entered }}</dd>
              <dt>Inventaris van archeologische zones</dt>
              <dd>{{ legal.heritage_inventory_archaeological_zones | yes_no_not_entered }}</dd>
              <dt>Inventaris van bouwkundig erfgoed</dt>
              <dd>{{ legal.heritage_inventory_architectural | yes_no_not_entered }}</dd>
              <template v-if="legal.use_old_landscape_fields">
                <dt>Inventaris van houtige beplantingen met erfgoedwaarde</dt>
                <dd>{{ legal.heritage_inventory_planting | yes_no_not_entered }}</dd>
                <dt>Inventaris van historische tuinen en parken</dt>
                <dd>{{ legal.heritage_inventory_historical_gardens_parks | yes_no_not_entered }}</dd>
              </template>
              <template v-else>
                <dt>Inventaris van landschappelijk erfgoed</dt>
                <dd>{{ legal.heritage_inventory_of_landscape | yes_no_not_entered }}</dd>
              </template>
              <dt>Geïnventariseerd onroerend erfgoed - extra informatie</dt>
              <dd>{{ (legal.heritage_inventory_info && legal.heritage_inventory_info.text_nl) || '-' }}</dd>
            </dl>
          </template>
        </template>
      </PanelBox>

      <PanelBox title="Energiewaardes">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyEnergyValuesEdit' }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <template v-if="energy && energy.epb_in_draft && property.is_newly_built && property.region == 0">
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>EPB in opmaak</dt>
            <dd>Ja</dd>
          </dl>
        </template>
        <template v-else>
          <!-- Region 0 = Flanders -->
          <dl v-if="energy && property.region == 0" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>EPC-kengetal (kWh/m²/jaar)</dt>
            <dd>{{ energy.epc_kwhm || '-' }}</dd>
            <dt>E-peil</dt>
            <dd>{{ energy.e_value || '-' }}</dd>
            <dt>EPC-label</dt>
            <dd>
              <EPCLabel :epc-label="energy.epc_label" />
            </dd>
            <dt>EPC-opstellingsdatum</dt>
            <dd>{{ energy.epc_date || '-' }}</dd>
            <dt>Unieke code</dt>
            <dd>{{ energy.epc_number || '-' }}</dd>
            <dt class="tw-mt-4">EPC-label kNR</dt>
            <dd class="tw-mt-4">
              <EPCLabel :epc-label="energy.epc_knr" />
            </dd>
            <dt>EPC kNR-opstellingsdatum</dt>
            <dd>{{ energy.epc_date_knr || '-' }}</dd>
            <dt>Unieke code kNR</dt>
            <dd>{{ energy.epc_number_knr  || '-' }}</dd>
            <dt class="tw-mt-4">EPC-label NR</dt>
            <dd class="tw-mt-4">
              <EPCLabel :epc-label="energy.epc_nr" />
            </dd>
            <dt>EPC NR-opstellingsdatum</dt>
            <dd>{{ energy.epc_date_nr || '-' }}</dd>
            <dt>Unieke code NR</dt>
            <dd>{{ energy.epc_number_nr  || '-' }}</dd>
            <dt>Aandeel hernieuwbare energie</dt>
            <dd>{{ percentage(energy.renewable_energy_share) }}</dd>
          </dl>

          <!-- Region 1 = Brussels -->
          <!-- Region 2 = Wallonia -->
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs" v-if="energy && (property.region === 1 || property.region === 2)">
            <dt>EPB-kengetal (kWh/m²/jaar)</dt>
            <dd>{{ energy.epc_kwhm || '-' }}</dd>
            <dt>EPB-label</dt>
            <dd>
              <EPCLabel :epc-label="energy.epc_label" />
            </dd>
            <dt>EPB-opstellingsdatum</dt>
            <dd>{{ energy.epc_date || '-' }}</dd>
            <dt>Unieke code</dt>
            <dd>{{ energy.epc_number || '-' }}</dd>
          </dl>
        </template>
      </PanelBox>

      <PanelBox title="Energie">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyEnergyEdit' }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <template v-if="energy">
          <h3>Algemeen</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>BEN-woning <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.aen_home | yes_no_unknown }}</dd>
            <dt>Conformiteit keuringsattest <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.conformity_test_certificate | yes_no_unknown }}</dd>
            <dt>Elektriciteitsmeter <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.electricity_meter_display }}</dd>
            <dt>Type elektriciteitsmeter <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.electricity_meter_type_display }}</dd>
            <dt>Attest voor stookolietank verkregen <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.fuel_oil_certificate | yes_no_unknown }}</dd>
            <dt>Stookolietank <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.fuel_oil_tank | yes_no_unknown }}</dd>
            <dt>Inhoud stookolietank (liter) <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.fuel_oil_tank_capacity || '-' }}</dd>
            <dt>Stookolietanktype <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.fuel_oil_tank_type_display }}</dd>
            <dt>Beglazing <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.glazing_display }}</dd>
            <dt>Groenstroomcertificaten <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.green_energy_certificates | yes_no_unknown }}</dd>
            <dt>Thuisbatterij <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.battery | yes_no_unknown }}</dd>
            <dt>Warmtepomp <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.heat_pump | yes_no_unknown }}</dd>
            <dt>Verwarmingsketel <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.heating_boiler_display }}</dd>
            <dt>Verwarmingsbron <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.heating_source_display }}</dd>
            <dt>Verwarmingstype <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.heating_type_display }}</dd>
            <dt>Keuringsattest - extra informatie</dt>
            <dd>{{ (energy.information_test_certificate && energy.information_test_certificate.text_nl) || '-' }}</dd>
            <dt>Meter voor aardgas <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.natural_gas_meter_display }}</dd>
            <dt>Zonnepanelen (kw) <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.solar_panels || '-' }}</dd>
            <dt>Zonneboiler (liter) <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.solar_water_heater || '-' }}</dd>
            <dt>Keuringsattest <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.test_cetificate | yes_no_unknown }}</dd>
            <dt>Watermeter <i class="far fa-globe-europe" /></dt>
            <dd>{{ energy.water_meter_display }}</dd>
            <dt>Windmolen</dt>
            <dd>{{ energy.windmill | yes_no_unknown }}</dd>
          </dl>

          <h3>Meters</h3>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>EAN/Meternummer elektriciteit</dt>
            <dd class="tw-flex tw-flex-wrap tw-justify-between">
              {{ energy.electricity_meter_number || '-' }}
              <a
                v-if="energy.electricity_meter_document"
                :href="energy.electricity_meter_document.url"
                target="_blank"
                title="Bekijk elektriciteitsmeterdocument"
              >
                <i class="far fa-file" /> Bekijk
              </a>
            </dd>
            <dt>EAN/Meternummer gas</dt>
            <dd class="tw-flex tw-flex-wrap tw-justify-between">
              {{ energy.gas_meter_number || '-' }}
              <a
                v-if="energy.gas_meter_document"
                :href="energy.gas_meter_document.url"
                target="_blank"
                title="Gasmeterdocument bekijken"
              >
                <i class="far fa-file" /> Bekijk
              </a>
            </dd>
            <dt>Meternummer water</dt>
            <dd class="tw-flex tw-flex-wrap tw-justify-between">
              {{ energy.water_meter_number || '-' }}
              <a
                v-if="energy.water_meter_document"
                :href="energy.water_meter_document.url"
                target="_blank"
                title="Bekijk het watermeterdocument"
              >
                <i class="far fa-file" /> Bekijk
              </a>
            </dd>
          </dl>
        </template>
      </PanelBox>

      <PanelBox title="Bedrijfstechnisch">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyBuildingEdit', params: { formName: 'commercial' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <dl v-if="commercial" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Vrije hoogte <i class="far fa-globe-europe" /></dt>
          <dd>{{ commercial.free_height || '-' }}</dd>
          <dt>kVA <i class="far fa-globe-europe" /></dt>
          <dd>{{ commercial.kva || '-' }}</dd>
          <dt>Lichtstraten <i class="far fa-globe-europe" /></dt>
          <dd>{{ commercial.light_street | yes_no_unknown }}</dd>
          <dt>Aantal poorten</dt>
          <dd>{{ commercial.number_of_ports || '-' }}</dd>
          <dt>Nokhoogte</dt>
          <dd>{{ commercial.ride_height || '-' }}</dd>
          <dt>Rolbrug <i class="far fa-globe-europe" /></dt>
          <dd>{{ commercial.rolling_bridge | yes_no_unknown }}</dd>
          <dt>Rolbrug - extra informatie</dt>
          <dd>{{ (commercial.rolling_bridge_description && commercial.rolling_bridge_description.text_nl) || '-' }}</dd>
          <dt>Afmeting grootste poort</dt>
          <dd>{{ commercial.size_of_largest_port || '-' }}</dd>
          <dt>3-fasenelektriciteit</dt>
          <dd>{{ commercial.three_phase_electric_power | yes_no_unknown }}</dd>
          <dt>Bruikbare kantooroppervlakte</dt>
          <dd>{{ commercial.usable_office_space || '-' }}</dd>
          <dt>Bruikbare opslagoppervlakte</dt>
          <dd>{{ commercial.usable_storage_space || '-' }}</dd>
          <dt>Laadkades <i class="far fa-globe-europe" /></dt>
          <dd>{{ commercial.loading_docks | yes_no_unknown }}</dd>
          <dt>Aantal laadkades</dt>
          <dd>{{ commercial.number_of_loading_docks || '-' }}</dd>
          <dt>Aantal parkeerplaatsen verplicht af te nemen</dt>
          <dd>{{ commercial.parking_spaces_required_for_purchase || '-' }}</dd>
        </dl>
      </PanelBox>
    </div>
    <EntityLegalNotices :property="property" ref="legalNoticesModal" />
  </div>
</template>

<script>
import { currency, formatDateForLocale, formatDateTime, percentage } from '@/utils/helpers'
import PanelBox from '@/components/iam/PanelBox'
import EPCLabel from '@/components/iam/EPCLabel'

import EntityLegalNotices from '@/components/properties/EntityLegalNotices'
import { propertyIsSales, propertyIsRental } from '@/utils/properties'

import { mapActions, mapGetters } from 'vuex'
import {
  getPropertyConcept,
  getPropertyComfort,
  getPropertyEnergy,
  getPropertyLegal,
  getPropertyCommercial,
  getPropertyTechnical,
  getPropertyBiddingRounds
} from '@/services/properties'
import { getAdvances } from '@/services/transactions'

export default {
  name: 'PropertyBuilding',
  components: {
    PanelBox,
    EPCLabel,
    EntityLegalNotices
  },
  data () {
    return {
      energy: null,
      technical: null,
      legal: null,
      concept: null,
      comfort: null,
      commercial: null,
      biddingRounds: [],
      advance: null
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    propertyId () {
      return this.$route.params.id
    },
    isRental () {
      return propertyIsRental(this.property)
    },
    isSales () {
      return propertyIsSales(this.property)
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    showWaterTestOldFields () {
      if (!this.property?.created_on) return false
      const createdDate = new Date(this.property.created_on)
      const firstOfJanuary2023 = new Date('2023-01-01')
      return firstOfJanuary2023.getTime() - createdDate.getTime() > 1
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', [
      'loadProperty'
    ]),
    currency,
    formatDateForLocale,
    formatDateTime,
    percentage,
    propertyIsSales,
    propertyIsRental,
    async init () {
      try {
        await Promise.all([
          this.loadProperty(this.propertyId),
          this.loadPropertyConcept(this.propertyId),
          this.loadPropertyEnergy(this.propertyId),
          this.loadPropertyLegal(this.propertyId),
          this.loadPropertyComfort(this.propertyId),
          this.loadPropertyCommercial(this.propertyId),
          this.loadPropertyTechnical(this.propertyId),
          this.loadAdvance(this.propertyId)
        ])
        if (this.concept.type === 6) {
          await this.loadBiddingRounds(this.propertyId)
        }
      } catch (error) {
        console.error(error)
      }
    },
    showLegalNotices () {
      this.$refs.legalNoticesModal.show()
    },
    getYesNo (value) {
      return value ? 'Ja' : 'Nee'
    },

    async loadPropertyConcept (propertyId) {
      const response = await getPropertyConcept(propertyId)
      this.concept = response.data
      return response
    },

    async loadPropertyComfort (propertyId) {
      const response = await getPropertyComfort(propertyId)
      this.comfort = response.data
      return response
    },

    async loadPropertyCommercial (propertyId) {
      const response = await getPropertyCommercial(propertyId)
      this.commercial = response.data
      return response
    },

    async loadPropertyEnergy (propertyId) {
      const response = await getPropertyEnergy(propertyId)
      this.energy = response.data
      return response
    },

    async loadPropertyLegal (propertyId) {
      const response = await getPropertyLegal(propertyId)
      this.legal = response.data
      return response
    },

    async loadPropertyTechnical (propertyId) {
      const response = await getPropertyTechnical(propertyId)
      this.technical = response.data
      return response
    },

    async loadBiddingRounds (propertyId) {
      const response = await getPropertyBiddingRounds(propertyId)
      this.biddingRounds = response.data?.results || []
      return response
    },

    async loadAdvance (propertyId) {
      const response = await getAdvances(
        {
          params: {
            property: propertyId,
            exclude_finished_advances: false
          }
        }
      )
      this.advance = response.data.results?.[0]
      return response
    }
  }
}
</script>
