<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="rentalAgreementStep3"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Pandstatus</h2>
      <FormulateInput
        name="status"
        type="radio"
        :options="[
          { value: 3, label: 'Te huur'},
          { value: 11, label: 'Verhuurd'}
        ]"
        :disabled="isDossierPreparation"
        :input-class="['tw-my-1']"
        :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
        outer-class="md:tw-w-1/3 tw-my-0"
      />

      <FormulateInput
        v-show="showPublishAsDewaeleReference"
        name="publish_as_dewaele_reference"
        type="checkbox"
        label="Publiceren/behouden als referentie op Dewaele.com"
        outer-class="tw-mt-2 tw-mb-4"
      />
    </div>
    <h2>Openstaande leads</h2>
    <WizardLeadCount :propertyId="propertyId" class="tw-mb-4" />

    <FormulateErrors class="tw-text-right" />

    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-bg-gray-500']"
        outer-class="tw-my-0"
        @click="goBackToStep2"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige stap
      </FormulateInput>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { mapActions } from 'vuex'
import { currency } from '@/utils/helpers'
import { propertyRentalAgreementProcessing, updateProperty } from '@/services/properties'
import { updateTransaction } from '@/services/transactions'

import WizardLeadCount from '@/components/properties/WizardLeadCount'

export default {
  name: 'WizardRentalAgreementStep3',
  components: {
    WizardLeadCount
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    stepValues: {
      type: Object,
      required: true
    }
  },
  constants: {
    CURRENT_STEP: 3,
    COMMISSION_TYPE_MONTHLY_RENT: 6,
    COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT: 17,
    COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT: 15,
    COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT: 9,
    COMMISSION_TYPE_OTHER: 12
  },
  created () {
    if (this.isDossierPreparation) {
      this.$set(this.values, 'status', '11')
    } else {
      this.$set(this.values, 'status', String(this.initialValues.status))
    }
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    isDossierPreparation () {
      return parseInt(this.initialValues.status) === 16
    },
    showPublishAsDewaeleReference () {
      return parseInt(this.values.status) === 11 && !this.isDossierPreparation
    }
  },
  methods: {
    currency,
    ...mapActions('properties', ['loadProperty']),
    goBackToStep2 () {
      this.$emit('go-back', this.CURRENT_STEP)
    },
    async updateProperty ({ status }) {
      const {
        agreement_signature_date,
        inventory_of_fixtures_tenant_amount,
        rental_dossier_costs,
        rental_management_type,
        rental_start_date,
        rental_term,
        should_deduct_rental_fee_from_first_months_rent
      } = this.stepValues.step1
      const payload = {
        agreement_signature_date,
        inventory_of_fixtures_tenant_amount,
        rental_dossier_costs,
        rental_management_type,
        rental_start_date,
        rental_term,
        should_deduct_rental_fee_from_first_months_rent,
        status
      }
      const response = await updateProperty(this.propertyId, payload)
      return response
    },
    async updateTransaction () {
      const transactionId = this.stepValues.step1.transaction_data?.id
      if (!transactionId) return
      const {
        agreement_signature_date,
        commission_fix_out,
        commission_type_out,
        price,
        transaction_cost,
        co_mandate_collaborator_percentage_out,
        co_mandate_other_percentage_out,
        co_mandate_percentage_type_out,
        mandate_type,
        rented_by
      } = this.stepValues.step1
      const payload = {
        commission_type_out,
        transaction_end_date: agreement_signature_date,
        transaction_price_out: price,
        transaction_end_status: 'R',
        transaction_cost,
        co_mandate_collaborator_percentage_out,
        co_mandate_other_percentage_out,
        co_mandate_percentage_type_out,
        mandate_type
      }
      if (rented_by === 'co-broker') {
        payload.verkoop_comandaat = true
      }
      if ([this.COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT, this.COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT, this.COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT].includes(parseInt(commission_type_out))) {
        let percentage = 0
        switch (parseInt(commission_type_out)) {
          case this.COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT:
            percentage = 5
            break
          case this.COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT:
            percentage = 10
            break
          case this.COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT:
            percentage = 15
            break
          default:
            percentage = 0
        }
        payload.commission_percentage_out = percentage
      } else {
        payload.commission_fix_value_out = commission_fix_out
      }
      const response = await updateTransaction(transactionId, payload)
      return response
    },
    async postRentalAgreement (data) {
      const payload = {
        publish_as_dewaele_reference: data.publish_as_dewaele_reference,
        advance_amount: this.stepValues.step1.third_party_amount,
        third_party_account: this.stepValues.step1.third_party_account,
        involvements: this.stepValues.step1.involvements
      }
      const response = await propertyRentalAgreementProcessing(this.propertyId, payload)
      return response
    },
    async submit (data) {
      try {
        const response = await Promise.all([this.updateProperty(data), this.updateTransaction()])
        const postProcessingResponse = await this.postRentalAgreement(data)
        this.$emit('finished')
        return [response, postProcessingResponse]
      } catch (error) {
        this.$formulate.handle(error, 'salesAgreementStep3')
        throw error
      }
    }
  }
}
</script>
