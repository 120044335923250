var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-xs"},[(_vm.showRoleLabel)?_c('span',{staticClass:"tw-inline-blocktw-border tw-rounded tw-px-1 tw-bg-gray-200 tw-mb-2"},[_vm._v(" "+_vm._s(_vm.getRoleDisplay(_vm.invoiceContact.role))+" ")]):_vm._e(),_c('div',{staticClass:"tw-mb-1 tw-text-sm tw-break-words tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.invoiceContact.title)+" "),(parseInt(_vm.invoiceContact.invoicing_share) !== 100)?_c('span',[_vm._v(" ("+_vm._s(_vm.percentage(_vm.invoiceContact.invoicing_share, 4))+") ")]):_vm._e()]),_c('div',{staticClass:"tw-my-1"},[_vm._v(_vm._s(_vm.getInvoiceContactAddress(_vm.invoiceContact)))]),(_vm.getInvoiceContactPrimary(_vm.invoiceContact).email)?_c('div',[_c('i',{staticClass:"fa fa-envelope"}),_c('Email',{attrs:{"type":"to","contact":_vm.getInvoiceContactPrimary(_vm.invoiceContact)}})],1):_vm._e(),_c('div',{staticClass:"tw-mt-1 tw-flex tw-flex-wrap tw-gap-4"},_vm._l((_vm.invoiceContact.contacts),function(ref){
var contact = ref.contact;
var primary = ref.primary;
return _c('router-link',{key:contact.id,class:[
        'label hover:!tw-underline',
        primary
          ? 'tw-bg-success !tw-text-white'
          : 'tw-bg-white tw-border tw-border-success !tw-text-success'
      ],attrs:{"to":{ name: 'ContactDetails', params: { id: contact.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(contact.display_name)+" ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }