var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FileDropzone',{attrs:{"max-files":10,"url":_vm.fileUploadUrl,"upload-multiple":true,"is-entity-file-upload":true},on:{"file-uploaded":_vm.fileUploaded,"all-uploaded":_vm.allUploaded},scopedSlots:_vm._u([{key:"droparea",fn:function(ref){
var loading = ref.loading;
return [_vm._t("droparea",null,{"loading":loading})]}}],null,true)}),_c('BaseModal',{ref:"moreInfo",attrs:{"title":"Bestanden uploaden","content-padding":"tw-px-4 tw-py-2 md:tw-px-8 tw-py-6","max-width":"!tw-overflow-visible tw-max-w-xl"},on:{"hide":function($event){_vm.uploadedFiles = []}}},[_c('FormulateForm',{attrs:{"debounce":"","name":"moreInformation"},on:{"submit":_vm.handleFileUpload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [(_vm.uploadedFiles.length > 1)?_c('div',{staticClass:"tw-pb-4 tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-border-b"},[_c('FormulateInput',{attrs:{"debounce":"","ignored":"","type":"toggle","name":"public","label":"Publiek","element-class":['tw-mr-auto'],"wrapper-class":['tw-flex-col'],"outer-class":"tw-my-0 tw-w-14"},on:{"input":function($event){return _vm.allModify('public', $event)}},model:{value:(_vm.allPublic),callback:function ($$v) {_vm.allPublic=$$v},expression:"allPublic"}}),_c('FormulateInput',{attrs:{"debounce":"","ignored":"","type":"autocomplete","auto-complete-type":"fileTypes","show-results-on-focus":true,"name":"file_type","label":"Bestandstype","placeholder":"Selecteer een bestandstype","input-class":['tw-h-8 tw-text-sm'],"outer-class":"tw-my-0"},on:{"input":function($event){return _vm.allModify('file_type', $event)}},model:{value:(_vm.selectedFileType),callback:function ($$v) {_vm.selectedFileType=$$v},expression:"selectedFileType"}})],1):_vm._e(),_c('FormulateInput',{attrs:{"type":"group","name":"files","debounce":"","value":_vm.uploadedFiles,"repeatable":true,"validation":"allFileTypesRequired","validation-rules":{ allFileTypesRequired: _vm.allFileTypesRequired },"validation-messages":{
          allFileTypesRequired: 'Alle bestandstypen zijn vereist'
        },"error-class":['tw-text-right'],"outer-class":"tw-mb-1.5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var index = ref.index;
return [_c('div',{staticClass:"tw-bg-white tw-border tw-rounded tw-shadow-md tw-p-3 tw-my-4"},[_c('div',{staticClass:"tw-w-full tw-py-1.5"},[(_vm.uploadedFiles.length === 1)?_c('label',{staticClass:"formulate-label"},[_vm._v(" Naam ")]):_vm._e(),(!_vm.fileIndexesForNameUpdate.includes(index))?_c('span',{staticClass:"tw-block tw-break-all tw-leading-tight tw-mb-0.5"},[_vm._v(" "+_vm._s(_vm.uploadedFiles[index] && _vm.uploadedFiles[index].filename)+" "),_c('button',{staticClass:"fa fa-pencil",attrs:{"type":"button"},on:{"click":function($event){return _vm.editFileName(index)}}})]):_c('FormulateInput',{attrs:{"type":"text","value":_vm.splitFileNameFromExtension(_vm.uploadedFiles[index].filename),"input-class":[
                  'tw-h-8 tw-text-sm tw-rounded-tr-none tw-rounded-br-none' ],"element-class":['tw-flex tw-items-center'],"outer-class":"tw-mt-0 tw-mb-0.5"},on:{"input":function($event){return _vm.handleFileNameInputChange(index, $event)}}},[_c('div',{staticClass:"input-unit tw-h-8",attrs:{"slot":"suffix"},slot:"suffix"},[_c('strong',[_vm._v("."+_vm._s(_vm.splitExtensionFromFileName(_vm.uploadedFiles[index].filename)))])])])],1),_c('div',{class:[
                'tw-flex sm:tw-flex-row tw-gap-x-5 tw-gap-y-4 tw-justify-between',
                { 'tw-py-1.5 tw-items-center': _vm.uploadedFiles.length > 1 }
              ]},[_c('FormulateInput',{attrs:{"type":"toggle","name":"public","label":_vm.uploadedFiles.length === 1 ? 'Publiek' : '',"element-class":['tw-mr-auto'],"wrapper-class":['tw-flex-col'],"outer-class":"tw-my-0 tw-w-14"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"fileTypes","show-results-on-focus":true,"name":"file_type","placeholder":"Selecteer een bestandstype","label":_vm.uploadedFiles.length === 1 ? 'Bestandstype' : '',"input-class":[
                  'tw-h-8 tw-text-sm',
                  { 'tw-mt-0': _vm.uploadedFiles.length > 1 }
                ],"outer-class":"tw-my-0 tw-flex-grow"}})],1),_c('div',{staticClass:"tw-my-2"},[_c('details',{staticClass:"tw-cursor-pointer"},[_c('summary',{staticClass:"tw-font-bold"},[_vm._v("Notities")]),_c('FormulateInput',{attrs:{"name":"notes","type":"textarea","placeholder":"Notities","input-class":['tw-h-14', 'tw-text-sm'],"outer-class":"tw-my-0"}})],1)])])]}},{key:"addmore",fn:function(){return undefined},proxy:true},{key:"remove",fn:function(){return undefined},proxy:true}],null,true)}),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":['tw-float-right']}},[_c('i',{class:[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }