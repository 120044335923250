<template>
  <div class="tw-flex tw-flex-wrap tw-gap-x-24">
    <div class="md:tw-w-2/5">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="invoiceForm"
        :form-errors="[noCostsSelectedMessage, costsDoNotHaveSameTypeMessage]"
        class="tw-w-full tw-max-w-2/5"
        @submit="submit"
      >
        <div class="tw-mb-8">
          <div class="tw-flex tw-justify-between tw-gap-4">
            <h2 class="tw-my-0">Kosten</h2>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-justify-end">
              <FormulateInput
                type="button"
                :input-class="['tw-px-2 tw-h-8 tw-text-sm']"
                outer-class="tw-my-0"
                @click="openCostsForm"
              >
                <i
                  :class="[
                    'fas tw-mr-0.5',
                    openingCostsForm ? 'fa-spinner-third fa-spin' : 'fa-plus'
                  ]"
                />
                Nieuwe kost
              </FormulateInput>
              <FormulateInput
                v-if="userHasInvoicingPermission"
                type="submit"
                :disabled="isLoading || allCostsInvoiced || noCostsSelected || costsDoNotHaveSameType"
                :input-class="['tw-px-2 tw-h-8 tw-text-sm']"
                outer-class="tw-my-0"
              >
                <i
                  :class="[
                    'fas tw-mr-0.5',
                    isLoading ? 'fa-spinner-third fa-spin' : 'fa-file-invoice'
                  ]"
                />
                {{ costType === 2 ? 'Start creditering' : 'Start facturatie' }}
              </FormulateInput>
            </div>
          </div>
          <FormulateErrors class="tw-text-right" />
        </div>

        <transition-group name="fade" mode="out-in">
          <div v-if="loadingCosts" key="loading" class="tw-h-10 tw-text-center">
            <i
              class="fal fa-spinner-third fa-spin tw-text-3xl"
            />
          </div>
          <div v-if="costs.length" key="costs">
            <div
              v-for="cost in costs"
              :key="cost.id"
              class="tw-my-6 tw-p-4 tw-rounded-md tw-shadow-card tw-relative"
            >
              <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start">
                <FormulateInput
                  v-model="values.cost_ids"
                  :id="`cost_${cost.id}`"
                  type="checkbox"
                  name="cost_ids"
                  :value="cost.id"
                  :ignored="['fully_invoiced', 'invoicing_failed'].includes(cost.invoicing_status)"
                  :outer-class="['!tw-my-0', { 'tw-opacity-0': ['fully_invoiced', 'invoicing_failed'].includes(cost.invoicing_status) ||  !userHasInvoicingPermission}]"
                />
                <label
                  :for="`cost_${cost.id}`"
                  class="formulate-label tw-font-normal tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-gap-4 tw-w-full"
                >
                  <div :title="cost.description">
                    <h4 v-if="cost.product" class="tw-my-0">
                      {{ cost.product.supplier.name }} -
                      {{ cost.product.product.name }}

                    <Tooltip class="tw-inline-block">
                      <i class="fas fa-info-circle" />
                      <template #popper>
                        <div class="tw-text-center">
                          <span v-if="cost.created_by" class="tw-font-semibold tw-block">
                            {{ cost.created_by.first_name }} {{ cost.created_by.last_name }}
                          </span>
                          <span class="tw-block">
                            {{ formatDateTime(cost.created_on) }}
                          </span>
                          <span v-if="cost.order">
                            Bestelstatus: {{ ORDER_STATUSES[cost.order.status] }}
                          </span>
                        </div>
                      </template>
                    </Tooltip>
                  </h4>
                  <span class="tw-text-xs">
                    {{ cost.description }}
                  </span>
                  <br />
                  <span
                    v-if="cost.entity_invoice_recipient_grouping"
                    class="tw-text-xs tw-mt-2 tw-inline-block tw-bg-gray-e9 tw-px-2 tw-border tw-rounded"
                  >
                    Facturatiecontact: {{ cost.entity_invoice_recipient_grouping.title }}
                  </span>
                </div>
                <div class="tw-text-right tw-mr-4">
                  <h4 class="tw-my-0">
                    <!--
                    A credit note can possibly have a zero value (while still having a discount amount to be credited)
                    Show the zero amount as a positive value instead of a negative -0.
                    -->
                    {{ cost.type === 1 || !parseFloat(cost.amount) ? currency(cost.amount) : currency(cost.amount * -1) }} excl. btw
                  </h4>
                  <div v-if="cost.discount_amount" class="tw-text-xs">
                    {{ currency(cost.discount_amount) }} korting excl. btw
                    <Tooltip v-if="cost.is_discount_settled" class="tw-inline-block">
                      <i class="fas fa-check-circle tw-text-[10px] tw-text-green-500" />
                      <template #popper>
                        <div class="tw-text-center">
                          De korting werd verrekend.
                        </div>
                      </template>
                    </Tooltip>
                  </div>
                  <div
                    class="tw-inline-block tw-font-bold tw-text-xs tw-mt-2 tw-bg-gray-e9 tw-px-2 tw-border tw-rounded"
                  >
                    {{ getCostInvoicingStatusLabel(cost) }}
                  </div>
                </div>
              </label>

                <div class="tw--translate-y-1/4 tw-translate-x-1/2 tw-absolute tw-top-0 tw-right-0 tw-flex tw-flex-col tw-gap-2">
                  <button
                    v-if="cost.type !== 2 && !cost.is_discount_settled"
                    type="button"
                    title="Bewerken"
                    class="
                      tw-px-1.5 tw-py-0.5 tw-border
                      tw-text-success tw-border-success tw-rounded-full tw-shadow-card tw-bg-white
                      hover:tw-bg-success hover:tw-text-white
                      disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
                    "
                    @click="editCost(cost)"
                  >
                    <i class="fas fa-pencil" />
                  </button>
                  <button
                    v-if="userCanDeleteCost(cost)"
                    type="button"
                    title="Verwijderen"
                    :disabled="deletingCostForId === cost.id"
                    class="
                      tw-px-1.5 tw-py-0.5 tw-border
                      tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white
                      hover:tw-bg-danger hover:tw-text-white
                      disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
                    "
                    @click="deleteCost(cost)"
                  >
                    <i :class="['fas', deletingCostForId === cost.id ? 'fa-spinner-third fa-spin' : 'fa-trash']" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p v-else-if="!loadingCosts" key="noCosts">Er zijn op dit moment geen kosten.</p>
        </transition-group>
      </FormulateForm>

      <BaseModal ref="editCostModal" title="Kost bewerken" max-width="tw-max-w-xl">
        <FormulateForm
          #default="{ isLoading }"
          v-model="editCostValues"
          name="editCostForm"
          @submit="updateCost"
        >
          <h4 v-if="editCostValues.product" class="tw-my-0 tw-text-center">
            {{ editCostValues.product.supplier.name }} -
            {{ editCostValues.product.product.name }}
          </h4>

          <FormulateInput
            type="number"
            name="amount"
            min="0"
            step=".01"
            label="Bedrag"
            placeholder="Bedrag"
            validation="bail|required|min:0.01"
            :disabled="isCostAmountAdjustmentDisabled"
          >
            <template #help v-if="editCostValues.amount && editCostValues.vat_percentage">
              Deze kost zal worden opgeslagen als
              <span class="tw-font-bold">
                {{ currency(amountExclVat(editCostValues)) }}
              </span>
              (excl. btw)
            </template>
          </FormulateInput>

          <fieldset class="tw-border-none tw-p-0">
            <label for="vat_percentage" class="formulate-label">
              Deze kost is
            </label>
            <div class="tw-mt-2 tw-flex tw-flex-wrap tw-items-end tw-gap-4">
              <FormulateInput
                type="radio"
                name="vat_percentage"
                label="Exclusief btw / 0% btw"
                value=""
                outer-class="tw-my-0"
              />
              <FormulateInput
                type="radio"
                name="vat_percentage"
                label="Inclusief 21% btw"
                value="21"
                outer-class="tw-my-0"
              />
            </div>
          </fieldset>

          <FormulateInput
            type="number"
            name="discount_amount"
            step=".01"
            placeholder="Korting/commerciële tegemoetkoming"
            label="Korting/commerciële tegemoetkoming"
            validation="optional|min:0"
            :disabled="isDiscountAmountAdjustDisabled"
          />

          <FormulateInput
            type="text"
            name="description"
            label="Beschrijving"
            placeholder="Beschrijving"
            validation="max:50,length"
            :disabled="isCostDescriptionAdjustmentDisabled"
          >
            <template #label>
              <label for="description" class="formulate-label">
                Beschrijving
                <Tooltip class="tw-inline-block">
                  <i class="fas fa-info-circle" />
                  <template #popper>
                    <div class="tw-text-center">
                      Adresgegevens worden automatisch toegevoegd in de factuurlijn en mogen hier niet worden ingegeven
                    </div>
                  </template>
                </Tooltip>
              </label>
            </template>
          </FormulateInput>

          <FormulateInput
            type="select"
            label="Facturatiecontact (optioneel)"
            placeholder="Selecteer een facturatiecontact"
            name="entity_invoice_recipient_grouping"
            :options="entityInvoiceRecipientGroupingsSelect"
            :element-class="['tw-flex']"
          >
            <template #suffix>
              <button
                v-if="editCostValues.entity_invoice_recipient_grouping"
                @click="unsetEntityInvoiceRecipientGrouping"
              >
                <i class="fa fa-trash tw-text-md tw-text-red-500 tw-ml-2 tw-mt-1" />
              </button>
            </template>
          </FormulateInput>

          <FormulateErrors class="tw-text-right" />

          <div class="tw-flex tw-justify-end">
            <FormulateInput type="submit" :disabled="isLoading" outer-class="tw-mt-2">
              <i
                :class="[
                  'fas tw-mr-1',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                ]"
              />
              Opslaan
            </FormulateInput>
          </div>
        </FormulateForm>
      </BaseModal>
      <BaseModal ref="entityInvoicePreviewModal" :title="previewTitle" max-width="tw-max-w-3xl">
        <EntityInvoicePreview
          ref="entityInvoicePreview"
          :entity-type="entityType"
          :entity-id="entityId"
          @invoicing-done="init"
        />
      </BaseModal>
      <BaseModal ref="createCostModal" title="Kost aanmaken" max-width="tw-max-w-xl">
        <FormulateForm
          #default="{ isLoading }"
          v-model="createCostValues"
          name="createCostForm"
          @submit="createCost"
        >
          <!-- Option 0 is a disabled placeholder option, which should not be submitted -->
          <FormulateInput
            type="select"
            name="product"
            label="Product"
            :options="productsSelect"
            :disabled="commissionFeeSelected"
            validation="required|min:1"
            :validation-messages="{
              min: 'Product is verplicht'
            }"
            error-behavior="submit"
            outer-class="tw-mb-1"
            @change="setDescription"
          />

          <button
            v-if="entityType === 'property' && !commissionFeeSelected"
            type="button"
            class="tw-underline"
            @click.prevent="setCommissionFeeProduct"
          >
            Manuele ereloonkost registreren? Klik hier.
          </button>

          <FormulateInput
            type="number"
            name="amount"
            min="0"
            step=".01"
            label="Bedrag"
            placeholder="Bedrag"
            validation="bail|required|min:0.01"
          >
            <template #help v-if="createCostValues.amount && createCostValues.vat_percentage">
              Deze kost zal worden opgeslagen als
              <span class="tw-font-bold">
                {{ currency(amountExclVat(createCostValues)) }}
              </span>
              (excl. btw)
            </template>
          </FormulateInput>

          <fieldset class="tw-border-none tw-p-0">
            <label for="vat_percentage" class="formulate-label">
              Deze kost is
            </label>
            <div class="tw-mt-2 tw-flex tw-flex-wrap tw-items-end tw-gap-4">
              <FormulateInput
                type="radio"
                name="vat_percentage"
                label="Exclusief btw / 0% btw"
                value=""
                outer-class="tw-my-0"
              />
              <FormulateInput
                type="radio"
                name="vat_percentage"
                label="Inclusief 21% btw"
                value="21"
                outer-class="tw-my-0"
              />
            </div>
          </fieldset>

          <FormulateInput
            type="text"
            name="description"
            label="Beschrijving"
            placeholder="Beschrijving"
            validation="max:50,length"
          >
            <template #label>
              <label for="description" class="formulate-label">
                Beschrijving
                <Tooltip class="tw-inline-block">
                  <i class="fas fa-info-circle" />
                  <template #popper>
                    <div class="tw-text-center">
                      Adresgegevens worden automatisch toegevoegd in de factuurlijn en mogen hier niet worden ingegeven
                    </div>
                  </template>
                </Tooltip>
              </label>
            </template>
          </FormulateInput>

          <FormulateErrors class="tw-text-right" />

          <div class="tw-flex tw-justify-end">
            <FormulateInput type="submit" :disabled="isLoading" outer-class="tw-mt-2">
              <i
                :class="[
                  'fas tw-mr-1',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                ]"
              />
              Opslaan
            </FormulateInput>
          </div>
        </FormulateForm>
      </BaseModal>
    </div>
    <div class="md:tw-w-1/2">
      <h2 class="tw-mt-0">Facturen</h2>
      <div v-if="loadingInvoices" key="loading" class="tw-h-10 tw-text-center">
        <i
          class="fal fa-spinner-third fa-spin tw-text-3xl"
        />
      </div>
      <div v-if="invoices.length" class="tw-mt-9">
        <EntityInvoiceCard
          v-for="invoice in invoices"
          :invoice="invoice"
          :key="invoice.id"
          @triggerInit="init"
          @reloadInvoices="fetchEntityInvoices"
        />
      </div>
      <div v-else-if="!loadingInvoices">Geen facturen beschikbaar.</div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { successModal, questionModal, errorModal, warningModal } from '@/modalMessages'
import { currency, percentage, formatDateTime } from '@/utils/helpers'

import EntityInvoiceCard from '@/components/properties/EntityInvoiceCard'
import EntityInvoicePreview from '@/components/properties/EntityInvoicePreview'

import { getSupplierProducts } from '@/services/orders'
import { createCost, getPropertyCommissionFeeProduct, getCosts, updateCost, deleteCost } from '@/services/costs'
import { getInvoices } from '@/services/invoices'

import { getPropertyConcept, getPropertyInvoiceContacts } from '@/services/properties'
import { getProjectInvoiceContacts } from '@/services/projects'
import { getAdvances } from '@/services/transactions'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PortfolioInvoice',
  components: {
    Tooltip,
    EntityInvoiceCard,
    EntityInvoicePreview
  },
  constants: {
    ORDER_STATUSES: {
      1: 'besteld',
      2: 'geleverd',
      3: 'mislukt'
    }
  },
  data () {
    return {
      propertyConcept: null,
      values: {
        cost_ids: []
      },
      costs: [],
      invoices: [],
      editCostValues: {},
      allCostsInvoiced: true,
      createCostValues: { is_invoiceable: true, description: '' },
      products: [],
      openingCostsForm: false,
      loadingCosts: false,
      loadingInvoices: false,
      deletingCostForId: null,
      previewTitle: 'Facturatie',
      fetchingInvoiceInformationForInvoiceId: null,
      commissionFeeSelected: false,
      entityInvoiceRecipientGroupingUnset: '',
      entityInvoiceRecipientGroupings: []
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),
    ...mapGetters('properties', ['getPropertyById']),
    entityType () {
      return this.$route.meta.entity_type
    },
    entityId () {
      return this.$route.params.id
    },
    noCostsSelected () {
      return (this.values.cost_ids?.length < 1 && !this.allCostsInvoiced)
    },
    noCostsSelectedMessage () {
      if (this.noCostsSelected) return 'Er moet ten minste één kost zijn geselecteerd.'
      else return null
    },
    costsDoNotHaveSameType () {
      const filteredCosts = this.costs.filter(cost => this.values?.cost_ids?.includes(cost.id))
      const types = filteredCosts.map(cost => cost.type)
      return (!(types.length === 0 || types.every(type => type === types[0])))
    },
    costsDoNotHaveSameTypeMessage () {
      if (this.costsDoNotHaveSameType) return 'Alle kosten moeten van hetzelfde type zijn.'
      else return null
    },
    costType () {
      const filteredCosts = this.costs.filter(cost => cost.id === this.values?.cost_ids?.[0])
      return filteredCosts?.[0]?.type
    },
    productsSelect () {
      let results = [{ value: 0, label: 'Selecteer een product', disabled: true }]
      results = [...results, ...this.products.map(item => { return { value: item.id, label: `${item.product.name} (${item.supplier.name})` } })]
      return results
    },
    isInvoiceable () {
      return this.createCostValues?.is_invoiceable
    },
    isCostDescriptionAdjustmentDisabled () {
      return ['fully_invoiced', 'invoicing_failed'].includes(this.editCostValues?.invoicing_status)
    },
    isCostAmountAdjustmentDisabled () {
      // Business required as agreed on 2024-02-08
      // A cost amount should always be editable if was created manually
      // A cost that was registered automatically should only be editable if it isn't blocked on product level
      if (['fully_invoiced', 'invoicing_failed'].includes(this.editCostValues?.invoicing_status)) return true
      if (this.editCostValues?.created_by) return false
      return !this.editCostValues.product?.product?.has_editable_cost_amount
    },
    isDiscountAmountAdjustDisabled () {
      // Product type 1 is commission fee, we shouldn't allow adding discounts to commission fee costs that have been invoiced
      // However, business requirements allow this for other products
      return (
        (
          this.editCostValues?.product?.product?.type === 1 && ['fully_invoiced', 'invoicing_failed'].includes(this.editCostValues?.invoicing_status)) ||
          this.editCostValues?.is_discount_settled
      )
    },
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },
    isSuperUser () {
      return this.user.is_superuser
    },
    userHasInvoicingPermission () {
      return this.isSuperUser || this.collaborator.hasPerm('CAN_MANAGE_AND_INVOICE_COST', true)
    },
    entityInvoiceRecipientGroupingsSelect () {
      return this.entityInvoiceRecipientGroupings.map(grouping => { return { label: `${grouping.title} (${this.getRole(grouping.role)})`, value: grouping.id } })
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    percentage,
    formatDateTime,
    async editCost (cost) {
      this.editCostValues = { ...cost }
      this.$set(this.editCostValues, 'entity_invoice_recipient_grouping', cost.entity_invoice_recipient_grouping?.id)
      const response = this.entityType === 'property' ? await getPropertyInvoiceContacts(this.entityId) : await getProjectInvoiceContacts(this.entityId)
      this.entityInvoiceRecipientGroupings = response?.data?.results
      this.$refs.editCostModal.show()
    },
    setDescription () {
      const product = this.products.find(item => item.id === parseInt(this.createCostValues.product))
      this.$set(this.createCostValues, 'description', product.product.name)
    },
    amountExclVat ({ amount, vat_percentage }) {
      let calculation = null
      if (!vat_percentage) calculation = parseFloat(amount)
      else calculation = parseFloat(amount) * 100 / (100 + parseFloat(vat_percentage))
      return calculation.toFixed(2)
    },

    getCostInvoicingStatusLabel (cost) {
      let text = ''
      if (cost.type === 1) {
        switch (cost.invoicing_status) {
          case 'invoicing_failed':
            text = 'Facturatie mislukt'
            break
          case 'fully_invoiced':
            text = 'Gefactureerd'
            break
          case 'partially_invoiced':
            text = 'Deels gefactureerd'
            break
          case 'not_invoiced':
            text = 'Te factureren'
            break
        }
      } else {
        switch (cost.invoicing_status) {
          case 'invoicing_failed':
            text = 'Creditering mislukt'
            break
          case 'fully_invoiced':
            text = 'Gecrediteerd'
            break
          case 'partially_invoiced':
            text = 'Deels gecrediteerd'
            break
          case 'not_invoiced':
            text = 'Te crediteren'
            break
        }
      }

      return text
    },

    getRole (role) {
      const roleMap = {
        1: 'eigenaar',
        2: 'koper',
        3: 'huurder',
        4: 'derde partij'
      }
      return roleMap[role]
    },

    userCanDeleteCost (cost) {
      // Only allow deletion if cost hasn't been invoiced
      if (cost.invoicing_status !== 'not_invoiced') return false
      // If cost doesn't have `created_by` set this means the cost has been created automatically.
      // In that case only a super user should be allowed to delete the cost
      if (cost.created_by) return true
      return this.isSuperUser
    },

    async setCommissionFeeProduct () {
      try {
        this.commissionFeeSelected = true
        const response = await getPropertyCommissionFeeProduct(this.entityId)
        this.products.push(response.data)
        this.$set(this.createCostValues, 'product', response.data.id)
        this.setDescription()
        return response.data
      } catch (error) {
        this.commissionFeeSelected = false
        if (error?.response?.data?.code === 'PROPERTY_WITHOUT_TRANSACTION') {
          return warningModal('Dit dossier heeft geen transactie. Er kan geen ereloonkost worden geregistreerd.')
        }
        return errorModal('Er ging iets mis bij het inladen van het ereloonproduct.')
      }
    },

    unsetEntityInvoiceRecipientGrouping () {
      this.entityInvoiceRecipientGroupingUnset = this.editCostValues.entity_invoice_recipient_grouping.title
      this.$set(this.editCostValues, 'entity_invoice_recipient_grouping', null)
    },

    async init () {
      const [costsResponse, invoicesResponse, propertyConceptResponse] = await Promise.all([this.fetchEntityCosts(), this.fetchEntityInvoices(), this.fetchPropertyConcept()])
      return [costsResponse, invoicesResponse, propertyConceptResponse]
    },

    async fetchPropertyConcept () {
      if (this.entityType === 'project') return
      const response = await getPropertyConcept(this.entityId)
      this.propertyConcept = response.data
      return response
    },

    async fetchEntityInvoices () {
      this.loadingInvoices = true
      const params = {
        page_size: 100,
        [this.entityType]: this.entityId,
        type: 1 // Load invoices only (type 1), credit notes (type 2) are nested under the invoice data
      }
      const response = await getInvoices({ params })
      this.invoices = response.data.results
      this.loadingInvoices = false
      return response
    },

    async fetchEntityCosts () {
      this.loadingCosts = true
      // We assume all costs are invoiced, till we encounter an un-invoiced cost.
      // This makes the UI start from a disabled state, which is desired.
      // Unnecessary validations aren't triggered either, this way.
      this.allCostsInvoiced = true

      const params = {
        is_invoiceable: true, // Fetch costs that are invoiceable
        page_size: 100, // Let's fetch all the costs at once, if they are too many, we will add pagination later.
        [this.entityType]: this.entityId
      }

      const response = await getCosts({ params })
      const costIds = []
      this.costs = response.data?.results.map(cost => {
        if (!['fully_invoiced', 'invoicing_failed'].includes(cost.invoicing_status)) {
          this.allCostsInvoiced = false
          costIds.push(cost.id)
        }
        return cost
      })
      this.$nextTick(() => this.$set(this.values, 'cost_ids', costIds))
      this.loadingCosts = false
      return response
    },
    async openCostsForm () {
      this.openingCostsForm = true
      const propertyResponse = this.entityType === 'property' ? await this.getPropertyById(this.entityId) : null
      const transaction_type = propertyResponse?.transaction_type
      const params = {}
      if (transaction_type) params.transaction_type = transaction_type
      const response = await getSupplierProducts(params)
      this.$set(this.createCostValues, 'is_invoiceable', true) // Fix re-opening form after closing it
      this.products = response?.data?.results
      this.$refs.createCostModal.show()
      this.openingCostsForm = false
    },

    async createCost (data) {
      try {
        data.amount = this.amountExclVat(data)
        data[this.entityType] = this.entityId
        const response = await createCost(data)
        await this.fetchEntityCosts()
        this.$refs.createCostModal.hide()
        successModal('Kost werd succesvol aangemaakt')
        this.commissionFeeSelected = false
        return response
      } catch (error) {
        this.$formulate.handle(error, 'createCostForm')
      }
    },
    async updateCost (data) {
      try {
        const { id, description, entity_invoice_recipient_grouping } = data
        let discount_amount = data.discount_amount
        if (!parseFloat(discount_amount)) discount_amount = null
        const response = await updateCost(
          id,
          {
            description,
            discount_amount,
            amount: this.amountExclVat(data),
            entity_invoice_recipient_grouping
          }
        )
        this.entityInvoiceRecipientGroupingUnset = false
        await this.fetchEntityCosts()
        this.$refs.editCostModal.hide()
        successModal('De kost is succesvol gewijzigd.')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'editCostForm')
      }
    },
    async deleteCost (cost) {
      try {
        const costName = []
        if (cost.product?.supplier) costName.push(cost.product.supplier.name)
        if (cost.product?.product) costName.push(cost.product.product.name)
        if (cost.description) costName.push(cost.description)

        const result = await questionModal(`Weet je zeker dat je de kost "${costName.join(' ')}" wilt verwijderen?`, '')
        if (!result.value) return

        this.deletingCostForId = cost.id
        const response = await deleteCost(cost.id)
        await this.fetchEntityCosts()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan de kosten niet verwijderen, probeer het opnieuw.')
      } finally {
        this.deletingCostForId = null
      }
    },
    async checkForOpenAdvances (data) {
      // returns true or false
      // true means the invoicing proceeds
      // false means the invoicing stops
      if (this.entityType === 'project') return true
      if (this.costType === 2) return true // No advances check in case of credit note
      const costIds = data.cost_ids
      const commissionCosts = this.costs.filter(cost => costIds.includes(cost.id) && cost.product.product.type === 1)
      if (commissionCosts.length === 0) return true
      const response = await getAdvances({ params: { property: this.entityId, exclude_finished_advances: true } })
      if (response?.data?.results?.length) {
        let openAdvancesMessage = ''
        const res = response.data.results.map(advance => this.currency(advance.amount)).join(' / ')
        if (response.data.results.length === 1) {
          openAdvancesMessage = `Er is een openstaand voorschot voor dit dossier ten bedrage van ${res}.`
        } else {
          openAdvancesMessage = `Er zijn ${response.data.results.length} openstaande voorschotten voor dit dossier: ${res}.`
        }
        const result = await questionModal(`${openAdvancesMessage} Ben je zeker dat je het ereloon wilt facturen?`, '')
        return result.value
      }
      return true
    },
    async submit (data) {
      try {
        if (this.noCostSelected) return false
        const result = await this.checkForOpenAdvances(data)
        if (!result) return
        this.previewTitle = this.costType === 1 ? 'Facturatie' : 'Creditering'
        this.$refs.entityInvoicePreviewModal.show()
        return await this.$nextTick(() => { return this.$refs.entityInvoicePreview.init(data) })
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'invoiceForm')
      }
    }
  }
}
</script>
