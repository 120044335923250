<template>
  <div>
    <div>
      <div v-for="(value, key, index) in involvementsForRoles" :key="`${key}_${index}`" class="tw-my-4">
        <p class="tw-font-bold md:tw-w-1/2 tw-border-b tw-mb-0">{{ getTitle(key) }}</p>
        <!-- Don't show validation due to custom non-vueformulate message, but a validation message with an empty space is required for the "required" validation to work-->
        <FormulateInput
          v-model="involvementsForRoles[key]"
          type="group"
          :name="`involvements_${key}`"
          validation="required"
          :validation-messages="{
              required: ' '
          }"
          :repeatable="true"
          remove-position="after"
          :group-repeatable-class="['tw-my-1 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full']"
          outer-class="tw-my-1"
          @repeatableRemoved="recalculateInvolvements(false)"
        >
          <template #addmore="{ addMore }">
            <button
              type="button"
              title="Poule toevoegen"
              class="
                tw-mt-1 tw-px-2 tw-py-0.5 tw-border
                tw-text-success tw-border-success tw-rounded-md tw-bg-white
                hover:tw-bg-success hover:tw-text-white
                disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
              "
              @click="addMore"
            >
              <i class="far fa-plus" /> Poule
            </button>
          </template>
          <template #remove="{ removeItem, index }">
            <template v-if="allowAdjustment(key, index)">
              <button
                type="button"
                title="Verwijderen"
                class="tw-h-10 tw-text-sm tw-rounded tw-text-danger"
                :disabled="!allowAdjustment(key, index)"
                @click="removeItem"
              >
                <i class="far fa-trash" />
              </button>
            </template>
          </template>
          <template #default="{ index }">
            <div class="tw-grid tw-grid-cols-5 tw-gap-x-2 md:tw-w-1/2">
              <FormulateInput
                type="autocomplete"
                auto-complete-type="poules"
                name="involvement"
                placeholder="Selecteer poule"
                :disabled="!allowAdjustment(key, index)"
                validation="bail|required|selectedPouleIsNoReferrer"
                :validation-rules="{ selectedPouleIsNoReferrer }"
                :validation-messages="{
                  required: 'Poule en percentage verplicht in te vullen',
                  selectedPouleIsNoReferrer: 'Verdeling aanbrengers ligt vast.\nContact de helpdesk voor wijzigingen.'
                }"
                :input-class="['tw-text-sm']"
                outer-class="tw-my-0"
                class="tw-col-span-4"
              />
              <FormulateInput
                type="number"
                min="1"
                max="100"
                name="percentage"
                placeholder="%"
                :validation="[
                  ['bail'],
                  ['requiredTotalPercentage', key],
                  ['min:1']
                ]"
                :validation-rules="{ requiredTotalPercentage }"
                :validation-messages="{
                  requiredTotalPercentage: `De som van de percentages moet gelijk zijn aan ${key === 'buyer' ? requiredPercentageForBuyerRole : requiredPercentageForSellerRole}`
                }"
                :element-class="['tw-flex tw-items-center']"
                :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-text-sm hide-number-input-arrows !tw-mt-1']"
                outer-class="tw-my-0 tw-inline"
                @input="recalculateInvolvements(false)"
              >
                <div slot="suffix" class="input-unit">
                  <i class="fas fa-percent" />
                </div>
              </FormulateInput>
            </div>
          </template>
        </FormulateInput>
      </div>
    </div>
    <div v-if="requiredMessage" class="tw-text-error">
      {{ requiredMessage }}
    </div>
    <div
      v-if="records && records.length"
      :class="['tw-p-4 tw-rounded tw-shadow-lg tw-mb-4 tw-w-full', !isValidInput ? 'tw-opacity-30' : '']"
    >
      <div v-for="record in records" :key="record.office_id" class="tw-mb-4 last:tw-mb-0">
        <div class="tw-font-bold tw-mb-2">Kantoor {{ record.office_reference }} ({{ percentage(record.office_share) }})</div>
        <table  class="tw-table-fixed tw-w-full tw-border-0.5 tw-border-collapse">
          <thead>
            <tr class="tw-font-bold">
              <td class="tw-p-1 tw-border tw-pl-2">Poule</td>
              <td class="tw-p-1 tw-border tw-pl-2">Rol</td>
              <td class="tw-p-1 tw-border tw-pl-2">In</td>
              <td :class="['tw-p-1 tw-border tw-pl-2', contextIsIntakePhase ? 'tw-text-gray-300' : '']">
                Uit <span v-if="contextIsIntakePhase">(*)</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="poule in record.poules" :key="poule.poule_id">
              <td class="tw-p-1 tw-border tw-pl-2">{{ poule.poule_name }}</td>
              <td class="tw-p-1 tw-border tw-pl-2">{{ getRole(poule.role) }}</td>
              <td class="tw-p-1 tw-border tw-pl-2">{{ percentage(poule.in) }}</td>
              <td :class="['tw-p-1 tw-border tw-pl-2', contextIsIntakePhase ? 'tw-text-gray-300' : '']">
                {{ percentage(poule.out) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-if="contextIsIntakePhase && records && records.length">
      (*) In deze dossierfase worden de uit-percentages gelijk verdeeld over de verschillende inkopers
      om zodoende de kantoorverdeling correct te kunnen berekenen en weer te geven. De uit-percentages worden niet opgeslagen
      en kunnen worden ingegeven bij de dossierafhandeling.
    </p>
  </div>
</template>

<script>
import { percentage } from '@/utils/helpers'
import { calculateTransactionInvolvementData, createTransactionOfficesAndPoules } from '@/services/transactions'
export default {
  name: 'TransactionInvolvementData',
  props: {
    propertyId: {
      type: [String, Number],
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      buyers: [],
      records: [],
      involvements: [],
      involvementsForRoles: {},
      isValidInput: true,
      referrerPouleIds: [],
      buyerPouleIds: []
    }
  },
  computed: {
    requiredMessage () {
      const hasBuyerSelections = this.involvementsForRoles?.buyer?.length
      const hasSellerSelections = this.involvementsForRoles?.seller?.length
      if (this.contextIsIntakePhase && !hasBuyerSelections) {
        return 'Selecteer minstens één poule'
      }
      if (!this.contextIsIntakePhase && !(hasBuyerSelections && hasSellerSelections)) {
        return "Selecteer minstens één poule bij 'Inkopers' en 'Verkopers'"
      }
      return ''
    },
    requiredPercentageForBuyerRole () {
      return this.referrerPouleIds.length ? 50 : 100
    },
    requiredPercentageForSellerRole () {
      return 100
    },
    contextIsIntakePhase () {
      return ['dossierPreparation', 'bidRegistration'].includes(this.context)
    }
  },
  created () {
    this.init()
  },
  methods: {
    percentage,
    async init () {
      const response = await this.recalculateInvolvements(true)
      this.setInvolvements()
      // Set the initial poule IDs for form validation purposes
      this.setPouleIds()
      return response
    },
    getTitle (role) {
      const roleLabelMap = {
        buyer: 'Inkopers',
        seller: 'Verkopers'
      }
      return roleLabelMap[role]
    },
    allowAdjustment (role, index) {
      if (role === 'seller') return true
      if (this.contextIsIntakePhase) return true
      const involvements = this.involvementsForRoles[role]
      const involvement = involvements[index]
      if (!involvement?.involvement) return true
      return !this.buyerPouleIds.includes(involvement.involvement.id)
    },
    getRole (role) {
      const roleMap = {
        buyer: 'Inkoper',
        referrer: 'Aanbrenger',
        seller: 'Verkoper',
        'buyer + seller': 'Inkoper + verkoper'
      }
      return roleMap[role]
    },
    requiredTotalPercentage (value, context) {
      if (!context) return
      const involvements = this.involvementsForRoles[context]
      const sumOfInvolvements = involvements?.reduce((total, record) => total + parseFloat(record.percentage), 0)
      const requiredPercentage = context === 'buyer' ? this.requiredPercentageForBuyerRole : this.requiredPercentageForSellerRole
      return sumOfInvolvements === requiredPercentage
    },
    selectedPouleIsNoReferrer (value) {
      return !this.referrerPouleIds.includes(value.value?.id)
    },
    setInvolvements () {
      const buyerInvolvements = []
      const sellerInvolvements = []
      const involvements = this.records
        .flatMap(office => office.poules)
        .filter(poule => ['buyer', 'seller', 'buyer + seller'].includes(poule.role))
      involvements.forEach(involvement => {
        if (involvement.role === 'buyer') {
          buyerInvolvements.push({ involvement: involvement, percentage: involvement.in })
        } else if (involvement.role === 'seller') {
          sellerInvolvements.push({ involvement: involvement, percentage: involvement.out })
        } else if (involvement.role === 'buyer + seller') {
          buyerInvolvements.push({ involvement: involvement, percentage: involvement.in })
          sellerInvolvements.push({ involvement: involvement, percentage: involvement.out })
        }
      })
      const obj = {}
      obj.buyer = buyerInvolvements
      if (!this.contextIsIntakePhase) {
        obj.seller = sellerInvolvements
      }
      this.involvementsForRoles = obj
    },
    getPouleIdsForRole (role) {
      const pouleIds = this.records
        .flatMap(office => office.poules)
        .filter(poule => poule.role === role)
        .map(poule => poule.id)
      return pouleIds
    },
    setPouleIds () {
      this.referrerPouleIds = this.getPouleIdsForRole('referrer')
      this.buyerPouleIds = this.getPouleIdsForRole('buyer')
    },
    getInvolvements () {
      const involvements = []
      for (const [role, records] of Object.entries(this.involvementsForRoles)) {
        // Check the record actually has an involvement selected using !!
        const result = records.filter(record => !!record.involvement).map(record => {
          return {
            role,
            poule_id: record.involvement.id,
            percentage: record.percentage || null
          }
        })
        involvements.push(...result)
      }
      return involvements
    },
    async recalculateInvolvements (init = false) {
      const rolesToCheck = ['buyer']
      if (!this.contextIsIntakePhase) rolesToCheck.push('seller')
      for (const role of rolesToCheck) {
        if (!this.requiredTotalPercentage(null, role)) {
          this.isValidInput = false
          break
        }
        this.isValidInput = true
      }
      const payload = {
        property_id: this.propertyId,
        type: this.contextIsIntakePhase ? 'involvements_table' : 'transaction'
      }
      if (!init) {
        payload.form_records = this.getInvolvements()
      }
      const response = await calculateTransactionInvolvementData(payload)
      this.records = response.data
      return response
    },
    async triggerSubmission () {
      const response = await createTransactionOfficesAndPoules(
        {
          property_id: this.propertyId,
          type: this.contextIsIntakePhase ? 'involvements_table' : 'transaction',
          form_records: this.getInvolvements()
        }
      )
      return response
    }
  }
}
</script>
