<template>
  <BaseModal ref="modal" :title="title">
    <p>
      <strong>Co-exclusieve opdracht.</strong>
      Er is een commissiesplit met de andere makelaar of promotor (bv. 80/20-afspraak).
    </p>
    <button
      type="button"
      class="btn btn-success tw-mb-4 btn-xs"
      @click="openAgreementWizard"
    >
      Afhandelingswizard opstarten
    </button>

    <p>
      <strong>Niet-exclusieve opdracht.</strong>
      Er is géén commissiesplit (0/100-afspraak).
    </p>
    <button
      type="button"
      class="btn btn-success btn-xs"
      @click="handleFinishedByOtherBroker"
    >
      Transactie beëindigen / Status wijzigen naar '{{ title }}'
    </button>
    <BaseModal
      title="Commissiegegevens"
      ref="transactionIntakeInputsModal"
      max-width="tw-max-w-4xl"
    >
      <FormulateForm #default="{ isLoading }" name="intakeForm" @submit="submit">
        <p>
          Opgelet: op dit dossier ontbreken de vereiste intakecommissiegegevens. Gelieve de gegevens aan te vullen, waarna de afhandelingswizard kan worden doorlopen.
        </p>
        <hr />
        <TransactionIntakeInputs
          ref="transactionIntakeInputs"
          :property="property"
          :prefill-co-mandate="true"
        />
        <h2 class="tw-mt-8 tw-mb-4">Betrokken kantoren en poules</h2>
        <TransactionInvolvementData
          ref="transactionInvolvements"
          context="bidRegistration"
          :property-id="property.id"
        />
        <div class="tw-flex tw-justify-end">
          <FormulateInput type="submit" :disabled="isLoading" outer-class="tw-mt-2">
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
              ]"
            />
            Naar afhandelingswizard
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </BaseModal>
</template>

<script>
import TransactionIntakeInputs from '@/components/transactions/TransactionIntakeInputs'
import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'
import { successModal } from '@/modalMessages'

import { updateProperty } from '@/services/properties'
import { clearTransactionPoules, updateTransaction } from '@/services/transactions'
import { toCalendarDate } from '@/utils/helpers'

export default {
  name: 'DossierFinishedByOtherBrokerModal',
  components: {
    TransactionIntakeInputs,
    TransactionInvolvementData
  },
  constants: {
    STATUS_SOLD_OTHER_BROKER: 13,
    STATUS_RENTED_OTHER_BROKER: 14,
    STATUS_ACQUIRED_OTHER_BROKER: 17,
    TRANSACTION_END_STATUS_HANDLED_BY_OTHER_BROKER: 'N'
  },
  props: {
    property: Object,
    title: String
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    openAgreementWizard () {
      // If no transaction, then show the form for the transaction intake inputs
      // After completing the information, emit an event to trigger the opening of either the sales or rental agreement wizard
      if (!this.property.transaction) {
        return this.$refs.transactionIntakeInputsModal.show()
      }
      this.$emit('openAgreementWizard', { soldByCoBroker: true })
      this.$refs.modal.hide()
    },
    async handleFinishedByOtherBroker () {
      const transactionTypeStatusMap = {
        2: this.STATUS_SOLD_OTHER_BROKER, // TRANSACTION_TYPE_FOR_SALE
        3: this.STATUS_RENTED_OTHER_BROKER, // TRANSACTION_TYPE_FOR_RENT
        4: this.STATUS_ACQUIRED_OTHER_BROKER // TRANSACTION_TYPE_ACQUISITION
      }
      const newStatus = transactionTypeStatusMap[this.property.transaction_type]
      const propertyResponse = await updateProperty(this.property.id, { stage_key: 'archival', status: newStatus })
      let transactionResponse = null
      let poulesResponse = null
      if (this.property.transaction) {
        const date = new Date()
        const currentDate = toCalendarDate(date)
        transactionResponse = await updateTransaction(this.property.transaction.id, { transaction_end_status: this.TRANSACTION_END_STATUS_HANDLED_BY_OTHER_BROKER, transaction_end_date: currentDate })
        poulesResponse = await clearTransactionPoules(this.property.transaction.id)
      }
      this.$emit('finishedByOtherBrokerCompleted')
      successModal("De transactie werd op 'beëindigd' gezet. De pandstatus werd gewijzigd.")
      return [propertyResponse, transactionResponse, poulesResponse]
    },
    async submit () {
      const intakeInputsResponse = await this.$refs.transactionIntakeInputs.triggerSubmission()
      const transactionInvolvementsResponse = await this.$refs.transactionInvolvements.triggerSubmission()
      this.$refs.transactionIntakeInputsModal.hide()
      this.$refs.modal.hide()
      this.$emit('openAgreementWizard', { finishedByCoBroker: true })
      return [intakeInputsResponse, transactionInvolvementsResponse]
    }
  }
}
</script>
