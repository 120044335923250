<template>
  <Modal title='Bestand aanpassen' ref="modal" @hide="reset">
    <div class="row" v-if="file">
      <div class="form-group">
        <div class="col-sm-12">
          <TranslatableField v-model="file.public_title" :label="'Publieke bestandsnaam'" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2" for="file-title"> Bestandsnaam: </label>
        <div class="col-sm-10">
          <div v-show="isS3File() && fileNameIncludesExtension" class="tw-text-error tw-mb-2">
            Naam mag geen extensie bevatten
          </div>
          <div class="tw-flex tw-items-center">
            <input
              id="file-title"
              class="form-control tw-h-10 tw-mb-2"
              :class="{ 'tw-w-full': isS3File() && getFileNameExtension().length }"
              v-model="fileName"
              placeholder="Voer bestandsnaam in">
            <div
              v-if="isS3File() && getFileNameExtension().length"
              class="tw-h-10 tw-w-1/12 tw-mb-2 tw-py-2 tw-bg-gray-200 tw-font-bold tw-text-center"
            >
              {{ getFileNameExtension() }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2" for="file-type"> Bestandstype: </label>
        <div class="col-sm-10 tw-mb-2">
          <select class="form-control" v-model="type" id="file-type">
            <option v-for="fileType in fileTypes" :key="fileType.id" :value="fileType.id">
              {{ fileType.name_nl }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2" for="public-checkbox"> Publiek: </label>
        <div class="col-sm-10">
          <input type="checkbox" v-model="publicFile" id="public-checkbox">
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="form-group">
        <label class="col-sm-2" for="file-notes"> Notities: </label>
        <div class="col-sm-10">
          <textarea
            id="file-notes"
            v-model="file.notes"
            placeholder="Voer notities in"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="btn btn-link" data-dismiss="modal" type="button">Annuleren</button>
      <button
        type="button"
        data-dismiss="modal"
        :disabled="!this.fileName || fileNameIncludesExtension"
        class="btn btn-success"
        @click="editFile"
      >
        Opslaan
      </button>
    </div>
  </Modal>

</template>

<script>
import Modal from '@/components/iam/Modal'
import TranslatableField from '@/components/iam/TranslatableField'

import { getEntityFileTypes } from '@/services/files'

export default {
  name: 'EntityFileModal',
  components: {
    TranslatableField,
    Modal
  },
  data () {
    return {
      type: 0,
      publicFile: true,
      fileName: '',
      file: null,
      fileTypes: []
    }
  },
  computed: {
    fileNameIncludesExtension () {
      const extensions = ['.pdf', '.doc', '.docx', 'xls', 'xslx', '.ppt', '.pptx', '.png', '.jpeg', '.html']
      return extensions.some(extension => this.fileName?.includes(extension))
    }
  },
  methods: {
    show () {
      this.loadFileTypes()
      this.$refs.modal.show()
      this.setFileNameWithoutExtension()
    },
    hide () {
      this.$refs.modal.hide()
      this.reset()
    },
    reset () {
      this.publicFile = true
      this.type = 0
      this.file = null
      this.fileName = ''
    },
    setFileNameWithoutExtension () {
      // Google Drive files have no extension and thus should not be split
      if (this.isS3File() && this.file?.filename?.includes('.')) {
        const substrings = this.file.filename.split('.')
        substrings.pop()
        this.fileName = substrings.join('.')
      } else {
        this.fileName = this.file?.filename
      }
    },
    editFile () {
      if (this.isS3File() && this.file.filename.includes('.')) {
        this.file.filename = this.fileName + this.getFileNameExtension()
      } else {
        this.file.filename = this.fileName
      }
      this.file.type_id = this.type
      this.file.type = this.fileTypes.filter(fileType => fileType.id === this.type).map(fileType => fileType.title)[0]
      this.file.public = this.publicFile
      this.file.url = encodeURI(this.file.url)
      this.$emit('edit', this.file)
    },
    getFileNameExtension () {
      if (this.file.filename.includes('.')) {
        return '.' + this.file.filename.split('.').pop()
      }
      return ''
    },
    isS3File () {
      // Only Google Drive files have a source_id attribute
      return !this.file?.source_id
    },

    async loadFileTypes () {
      const response = await getEntityFileTypes()
      this.fileTypes = response?.data?.results
      return response
    }
  }
}
</script>
