<template>
  <BaseModal ref="permanenceModal" title="Secretariaat" max-width="tw-max-w-3xl">
    <FormulateForm
      v-model="values"
      #default="{ isLoading }"
      name="contactsCreate"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="sendPermanenceMail"
    >
      <div>
        <FormulateInput
          type="autocomplete"
          auto-complete-type="property"
          name="property"
          label="Pand"
          placeholder="Selecteer pand"
          @input="fetchPropertyCollaborator"
        />
        <FormulateInput
          type="autocomplete"
          auto-complete-type="collaborator"
          name="collaborator"
          label="Medewerker"
          placeholder="Selecteer medewerker"
          validation="required"
          :show-required-label="true"
        />
        <FormulateInput
          type="autocomplete"
          auto-complete-type="contact"
          name="contact"
          label="Contact"
          placeholder="Selecteer contact"
          @input="handleContactChange"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
              <button
                type="button"
                class="link tw-font-semibold"
                @click="showContactCreateModal"
              >
                <i class="far fa-user-plus" /> Nieuw
              </button>
            </div>
          </template>
        </FormulateInput>
        <template v-if="values.contact">
          <div v-if="values.contact.type === 'B'">
            <FormulateInput
              v-model="values.contact.company_name"
              type="text"
              name="company_name"
              label="Bedrijfsnaam"
              outer-class="tw-m-0"
              :show-required-label="true"
              :validation="values.contact.type === 'B' ? 'required' : ''"
            />
          </div>
          <div v-if="values.contact.type === 'P'" class="tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              v-model="values.contact.first_name"
              type="text"
              name="first_name"
              label="Voornaam"
              outer-class="tw-m-0"
              :show-required-label="true"
              :validation="values.contact.type === 'P' ? 'required' : ''"
            />
            <FormulateInput
              v-model="values.contact.last_name"
              type="text"
              name="last_name"
              label="Naam"
              outer-class="tw-m-0"
              :show-required-label="true"
              :validation="values.contact.type === 'P' ? 'required' : ''"
            />
          </div>
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-mt-4">
            <FormulateInput
              v-model="values.contact.mobile"
              type="dial_code_tel"
              name="mobile"
              label="Gsm"
              validation="bail|optional|phone|noDuplicateDialCode"
              outer-class="tw-m-0"
            />
            <FormulateInput
              v-model="values.contact.phone"
              type="dial_code_tel"
              name="phone"
              label="Telefoon"
              validation="bail|optional|phone"
              outer-class="tw-m-0"
            />
            <FormulateInput
              v-model.trim="values.contact.email"
              type="email"
              name="email"
              label="E-mail"
              validation="bail|optional|email"
              outer-class="tw-m-0 tw-col-span-2"
            />
            <span v-if="noContactMethod" v-text="noContactMethod" class="input-error"/>
          </div>
        </template>

        <FormulateInput
          type="textarea"
          name="message"
          label="Bericht"
          placeholder="Bericht"
          validation="required"
          :show-required-label="true"
        />
      </div>
        <div class="tw-flex tw-flex-col tw-items-end tw-w-full">
        <FormulateInput
          type="submit"
          :disabled="noContactMethod"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-paper-plane'
            ]"
          />
          Verzenden
        </FormulateInput>
      </div>
    </FormulateForm>
    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newContactCreated"
    />
  </BaseModal>
</template>

<script>
import ContactCreateModal from '@/components/contacts/ContactCreateModal'
import { updateContact } from '@/services/contacts'
import { errorModal, successModal } from '@/modalMessages'
import { sendPermanenceEmail } from '@/services/apiService'
import { getPropertyCollaborators } from '@/services/properties'

export default {
  name: 'PermanenceModal',
  components: {
    ContactCreateModal
  },
  data () {
    return {
      values: {},
      originalContact: null,
      contactHasChanged: false
    }
  },
  watch: {
    'values.contact': {
      handler (newContact) {
        // Check if the contact field has changed
        if (this.values.contact?.id) this.contactHasChanged = JSON.stringify(newContact) !== JSON.stringify(this.originalContact)
      },
      deep: true // Enable deep watching to detect changes within the contact object
    }
  },
  computed: {
    noContactMethod () {
      // It is mandatory only for contact type "Person"
      if (!this.values.contact) return false
      const { email, phone, mobile, type } = this.values.contact
      return !(email || phone || mobile) && type === 'P'
        ? 'Er is minimaal één e-mailadres, telefoonnummer of mobiel telefoonnummer vereist.'
        : false
    }
  },
  methods: {
    clear () {
      this.values = {}
    },
    show () {
      this.showContent = true
      this.showContactCreate = false
      this.$refs.permanenceModal.show()
    },
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    hide () {
      this.clear()
      this.$refs.permanenceModal.hide()
    },
    handleContactChange () {
      this.contactHasChanged = false
      if (this.values.contact?.id) {} this.originalContact = { ...this.values.contact }
    },
    async fetchPropertyCollaborator () {
      const payload = {
        propertyId: this.values.property.id
      }
      const response = await getPropertyCollaborators(payload)
      const collaborator = response.data?.results.sort((collaborator1, collaborator2) => collaborator2.primary - collaborator1.primary)?.[0]
      this.$set(this.values, 'collaborator', collaborator)
      return response
    },
    newContactCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'contact', { display_name, ...contact })
      return contact
    },
    async sendPermanenceMail () {
      try {
        if (this.values?.contact && this.contactHasChanged) {
          const contactPayload = {
            id: this.values.contact.id,
            first_name: this.values.first_name,
            last_name: this.values.last_name,
            company_name: this.values.company_name,
            mobile: this.values.mobile,
            phone: this.values.phone,
            email: this.values.email
          }
          await updateContact(contactPayload)
        }
        const mailPayload = {
          contact_id: this.values.contact?.id,
          collaborator_id: this.values.collaborator?.id,
          property_id: this.values.property?.id,
          message: this.values.message
        }
        const response = await sendPermanenceEmail(mailPayload)
        successModal('De e-mail werd succesvol verstuurd.')
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan e-mail niet versturen, gelieve opnieuw te proberen')
      }
    }
  }
}
</script>
