<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="salesAgreementStep3"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <FormulateInput
        type="radio"
        name="status"
        label="Pandstatus"
        validation="required"
        :disabled="isDossierPreparation"
        :options="statusOptions"
        :input-class="['tw-my-1']"
        outer-class="['tw-my-0']"
        :element-class="['tw-flex tw-flex-row tw-gap-4 tw-items-center']"
      />
      <div v-if="isDossierPreparation">
        Een pand in de dossieropbouwfase wordt automatisch op '{{ soldOrAcquired }}' gezet bij het opslaan van dit formulier.
      </div>
      <FormulateInput
        v-if="parseInt(values.status) === 2"
        type="radio"
        name="sticker_id"
        label="Sticker"
        value=""
        :options="[
          { value: '', label: 'Geen' },
          { value: 10, label: 'Waarschijnlijk verkocht' }
        ]"
        :input-class="['tw-my-1']"
        :element-class="['tw-flex tw-flex-row tw-gap-4 tw-items-center']"
      />
    </div>

    <h2>Openstaande leads</h2>
    <WizardLeadCount :property-id="propertyId" />

    <FormulateErrors class="tw-my-4 tw-text-right" />

    <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-bg-gray-500']"
        outer-class="tw-my-0"
        @click="goBackToStep2"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige stap
      </FormulateInput>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { mapActions } from 'vuex'
import { today } from '@/utils/helpers'
import { updateTransaction } from '@/services/transactions'
import {
  updateProperty,
  propertySalesAgreementProcessing
} from '@/services/properties'
import WizardLeadCount from '@/components/properties/WizardLeadCount'

export default {
  name: 'WizardSalesAgreementStep3',
  components: {
    WizardLeadCount
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    stepValues: {
      type: Object,
      required: true
    }
  },
  constants: {
    CURRENT_STEP: 3
  },
  data () {
    return {
      values: {},
      leadCount: null,
      advanceCreated: false,
      stickerCreated: false
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    statusOptions () {
      const saleStatuses = {
        2: 'Te koop',
        10: 'Verkocht'
      }
      const acquisitionStatuses = {
        4: 'Over te nemen',
        12: 'Overgenomen'
      }
      return this.property.transaction_type === 2 ? saleStatuses : acquisitionStatuses
    },
    soldOrAcquired () {
      return this.property.transaction_type === 2 ? 'Verkocht' : 'Overgenomen'
    },
    isDossierPreparation () {
      return this.stepValues?.step1?.status === 16
    }
  },
  watch: {
    stepValues: {
      handler (value) {
        // In case there's a suspensive condition ("ovab"), the default selected status should be the active status
        // In case the property's status is "dossier preparation", new status should should be "SOLD" (=10) for sales properties (2) and "ACQUIRED" (=12) for acquisition properties
        if (this.stepValues?.step1?.transaction_data?.[0]?.ovab && !this.isDossierPreparation) {
          this.$set(this.values, 'status', this.property.status.toString())
        } else {
          const finishedStatus = this.property.transaction_type === 4 ? '12' : '10'
          this.$set(this.values, 'status', finishedStatus)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('properties', ['loadProperty']),

    goBackToStep2 () {
      this.$emit('go-back', this.CURRENT_STEP)
    },

    async updateProperty (status) {
      const {
        deed_date,
        advance_payment,
        advance_payment_account,
        sales_value_adjustment_type,
        sales_value_adjustment_remark,
        sales_value_adjustment_amount,
        agreement_signature_date,
        should_deduct_sales_fee_from_advance_payment,
        sale_with_shares
      } = this.stepValues.step1

      const { notary_of_buyers, notary_of_owners } = this.stepValues.step2

      const payload = {
        deed_date,
        notary_of_owners: notary_of_owners.id,
        notary_of_buyers: notary_of_buyers.id,
        advance_payment,
        advance_payment_account,
        sales_value_adjustment_type,
        sales_value_adjustment_remark,
        sales_value_adjustment_amount,
        agreement_signature_date,
        sale_with_shares,
        should_deduct_sales_fee_from_advance_payment,
        status
      }

      const response = await updateProperty(this.propertyId, payload)
      return response
    },

    async handleCommissions () {
      const {
        commission_fix_in,
        co_mandate_collaborator_percentage_out,
        co_mandate_other_percentage_out,
        co_mandate_percentage_type_out,
        commission_percentage_out,
        overridden_commission,
        transaction_data,
        transaction_price_in,
        transaction_price_out,
        sold_by,
        is_investment_property_for_rent
      } = this.stepValues.step1

      if (!transaction_data?.[0]) return

      const {
        id,
        commission_fix_value_in,
        commission_percentage_in,
        mandate_type,
        ovab,
        ovab_type,
        ovab_date,
        ovab_due_date,
        ovab_information,
        transaction_end_date
      } = transaction_data?.[0]

      const payload = {
        co_mandate_percentage_type_out,
        co_mandate_collaborator_percentage_out,
        co_mandate_other_percentage_out,
        commission_fix_in,
        mandate_type,
        ovab,
        ovab_type,
        ovab_date,
        ovab_due_date,
        ovab_information,
        transaction_price_in,
        transaction_price_out,
        transaction_end_status: 'S', // Could possibly be empty
        usage: is_investment_property_for_rent ? 'B' : 'O' // B = Belegging, O = Onbekend
      }

      if (sold_by === 'co-broker') {
        payload.verkoop_comandaat = true
      }

      // Include the `commission_percentage_out` in the payload
      // This is either the original `commission_percentage_in` field OR, if a discount is granted, the field `commission_percentage_out`

      if (commission_percentage_out) {
        payload.commission_percentage_out = commission_percentage_out
      } else {
        payload.commission_percentage_out = commission_percentage_in
      }

      // Implement logic to include `commission_fix_value_out` in case of fix
      if (commission_fix_value_in) {
        payload.commission_fix_value_out = commission_fix_in
      }

      if (overridden_commission) {
        payload.commission_fix_value_out = overridden_commission
        payload.commission_percentage_out = null
      }

      if (!transaction_end_date) {
        payload.transaction_end_date = today.date
      }

      const response = await updateTransaction(id, payload)
      return response
    },

    async doAdditionalProcessing (sticker_id) {
      // API call to do additional back-end processing
      const payload = {
        google_review_contact_ids: this.stepValues.step2?.google_review_contact_ids || [],
        cost_discount_amount: this.stepValues.step1?.commission_fix_discount || 0,
        third_party_account: this.stepValues.step1?.third_party_account,
        advance_amount: this.stepValues.step1?.advance_payment,
        should_deduct_sales_fee_from_advance_payment: this.stepValues.step1?.should_deduct_sales_fee_from_advance_payment,
        involvements: this.stepValues.step1?.involvements,
        is_probably_sold: !!sticker_id
      }
      const response = await propertySalesAgreementProcessing(this.propertyId, payload)
      return response
    },

    async submit (data) {
      try {
        const { sticker_id, status } = data
        const promises = [
          this.handleCommissions(),
          this.updateProperty(status)
        ]

        const response = await Promise.all(promises)
        await this.doAdditionalProcessing(sticker_id)
        this.$emit('finished', data, this.CURRENT_STEP)
        return response
      } catch (error) {
        this.$formulate.handle(error, 'salesAgreementStep3')
        throw error
      }
    }
  }
}
</script>
