<template>
  <div :class="['tw-p-4 tw-rounded-md tw-shadow-card tw-relative', isCreditNote ? 'tw-bg-gray-e9 tw-mt-6' : 'tw-my-6']">
    <div v-if="isCollapsed">
      <span class="tw-font-bold">Gecrediteerd op {{ invoice.invoice_date | date-day }} | {{ invoice.invoice_number }} | </span>
      <button type="button" title="Creditnotadetails tonen" @click="isCollapsed = false">
        <i class="fa fa-eye" />
      </button>
    </div>
    <div v-else>
      <div class="tw-flex tw-justify-between tw-mb-1">
        <div class="tw-flex tw-flex-col tw-align-middle">
          <div>
            <span class="tw-font-bold">{{ invoice.type === 1 ? 'Factuur' : 'Creditnota' }} | {{ invoice.invoice_number }} | {{ invoice.invoice_date | date-day }}</span>
          </div>
          <div>
            {{ formatInvoiceContact(invoice )}}
          </div>
          <div v-if="invoice.structured_reference">
            <span>OGM: {{ invoice.structured_reference }}</span>
          </div>
        </div>
        <div v-if="invoice.payment_status" class="tw-flex tw-flex-col tw-mr-4">
          <div>
            <span :class="`tw-px-2 tw-rounded tw-text-white tw-font-bold ${getPaymentStatusBackgroundColor(invoice.payment_status)}`">
              {{ getPaymentStatusLabel(invoice) }}
            </span>
            <button
              v-if="showManualPaymentButton(invoice)"
              type="button"
              title="Manueel betaling triggeren"
              @click="triggerManualPayment(invoice)"
              class="tw-ml-1"
            >
              <i
                :class="['fa', invoice.id === triggeringManualPaymentForInvoiceId ? 'fa-spinner-third fa-spin' : 'fa-check']"
              />
            </button>
          </div>
        </div>
        <div v-else>
          <button
            type="button"
            title="Details laden"
            class="btn btn-warning btn-xs tw-font-bold"
            @click="fetchBoCountInvoiceInformation(invoice)"
          >
              <i
                :class="['fa tw-mr-1', invoice.id === fetchingInvoiceInformationForInvoiceId ? 'fa-spinner-third fa-spin' : 'fa-refresh']"
              />
            Details laden
          </button>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-y-2 tw-translate-x-1/2 tw-absolute tw--top-4 tw-right-0">
          <a
            v-if="invoice.property_file"
            :href="invoice.property_file.url"
            target="_blank"
            class="
              tw-px-1.5 tw-py-1 tw-border
              tw-text-success tw-border-success tw-rounded-full tw-shadow-card tw-bg-white
              hover:tw-bg-success hover:tw-text-white
            "
          >
            <i class="fas fa-download tw-text-base" />
          </a>
          <button
            v-if="(invoice.property_file || invoice.project_file)"
            type="button"
            title="Verstuur via e-mail"
            class="
              tw-px-1.5 tw-py-1 tw-border
              tw-text-success tw-border-success tw-rounded-full tw-shadow-card tw-bg-white
              hover:tw-bg-success hover:tw-text-white
              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
            "
            @click="openMailClient(invoice)"
          >
            <i :class="['fas', false ? 'fa-spinner-third fa-spin' : 'fa-envelope']" />
          </button>
          <button
            v-if="!invoice.credit_note && invoice.type !== 2"
            type="button"
            title="Maak creditnota"
            class="
              tw-px-1.5 tw-py-1 tw-border
              tw-text-green-500 tw-border-green-500 tw-rounded-full tw-shadow-card tw-bg-white
              hover:tw-bg-green-500 hover:tw-text-white
              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
            "
            @click="openCreditNoteModal(invoice.id)"
          >
            <i :class="['fas', false ? 'fa-spinner-third fa-spin' : 'fa-file']" />
          </button>
        </div>
      </div>
      <div class="tw-mb-4">
        <div v-if="invoice.invoice_amount_excl_vat && invoice.invoice_amount_incl_vat">
          <span>{{ currency(invoice.invoice_amount_excl_vat) }} excl. btw - {{ currency(invoice.invoice_amount_incl_vat) }} incl. btw</span>
        </div>
      </div>
      <div v-if="invoice.invoice_lines.length" class="">
        <div v-for="line in invoice.invoice_lines" :key="line.id" class="tw-mb-2 tw-border-l-2 tw-pl-2">
          <div class="tw-font-bold">
            <span>{{ line.cost.product.supplier.name }} | {{ line.cost.product.product.name }} | {{ currency(line.amount) }}</span>
            <span v-if="line.vat_code"> | btw: {{ line.vat_code.name }}</span>
          </div>
          <div>{{ line.description }}</div>
        </div>
      </div>
    </div>
    <EntityInvoiceCommunication v-show="!isCollapsed" :messages="invoice.invoice_messages" />
    <EntityInvoiceCard
      v-if="invoice.credit_note"
      :invoice="invoice.credit_note"
      :is-credit-note="true"
    />
    <EntityCreditNoteModal
      ref="creditingModal"
      @creditingDone="handleCreditingDone"
    />
  </div>
</template>

<script>
import EventBus from '@/components/iam/bus'
import EntityInvoiceCommunication from '@/components/properties/EntityInvoiceCommunication'
import EntityCreditNoteModal from '@/components/properties/EntityCreditNoteModal'

import { currency, percentage } from '@/utils/helpers'
import { getInvoiceEmailData, loadBoCountInvoiceInformation, markInvoicePaid } from '@/services/invoices'
import { errorModal } from '@/modalMessages'

export default {
  name: 'EntityInvoiceCard',
  components: {
    EntityCreditNoteModal,
    EntityInvoiceCommunication
  },
  props: {
    invoice: {
      type: Object
    },
    isCreditNote: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      triggeringManualPaymentForInvoiceId: null,
      isCollapsed: this.isCreditNote // Collapse the details of a credit note by default
    }
  },
  computed: {
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    }
  },
  methods: {
    currency,
    percentage,
    formatInvoiceContact (invoice) {
      const invoiceContact = invoice.entity_invoice_recipient_grouping
      if (!invoiceContact) return invoice.invoice_recipient_grouping.title
      const role = this.getRole(invoiceContact.role)
      const invoicingShare = invoiceContact.invoicing_share < 100 ? ` - ${percentage(invoiceContact.invoicing_share, 4)} facturatieaandeel` : ''
      return `${invoiceContact.title} (${role}${invoicingShare})`
    },

    getPaymentStatusBackgroundColor (paymentStatus) {
      const colorMap = {
        paid: 'tw-bg-green-500',
        refunded: 'tw-bg-green-500',
        partially_paid: 'tw-bg-warning',
        partially_refunded: 'tw-bg-warning',
        unpaid: 'tw-bg-gray-500',
        unrefunded: 'tw-bg-gray-500'
      }
      return colorMap[paymentStatus]
    },

    getPaymentStatusLabel (invoice) {
      const labelMap = {
        paid: 'Betaald',
        partially_paid: `Gedeeltelijk betaald, openstaand saldo: ${this.currency(invoice.balance)}`,
        unpaid: 'Niet betaald',
        refunded: 'Terugbetaald',
        partially_refunded: `Gedeeltelijk terugbetaald, openstaand saldo: ${this.currency(invoice.balance)}`,
        unrefunded: 'Niet terugbetaald'
      }
      return labelMap[invoice.payment_status]
    },

    getRole (role) {
      const roleMap = {
        1: 'eigenaar',
        2: 'koper',
        3: 'huurder',
        4: 'derde partij'
      }
      return roleMap[role]
    },

    showManualPaymentButton (invoice) {
      if (['paid', 'refunded'].includes(invoice.payment_status)) return false
      if (this.production) return false
      return true
    },

    openCreditNoteModal (invoiceId) {
      this.$refs.creditingModal.show(invoiceId)
    },

    handleCreditingDone () {
      this.$emit('triggerInit')
    },

    async openMailClient (invoice) {
      try {
        const response = await getInvoiceEmailData(invoice.id)
        const contacts = [
          { type: 'to', contact: response.data.to_recipient }
        ]
        const ccRecipients = response.data.cc_recipients
        ccRecipients.forEach(rcp => { contacts.push({ type: 'cc', contact: rcp.contact }) })
        const context = { invoiceId: invoice.id }
        if (response.data.property) {
          context.properties = [response.data.property]
        } else if (response.data.project) {
          context.project = response.data.project
        }

        EventBus.$emit('mailclient-prefill-and-show', {
          contacts,
          template: {
            key: 'invoice_mailclient',
            language: response.data.to_recipient.language
          },
          attachments: [response.data.attachment],
          context: context,
          callback: async () => {
            this.$emit('reloadInvoices')
            EventBus.$emit('mailclient-close')
          }
        })
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het openen van de mailclient.')
      }
    },
    async fetchBoCountInvoiceInformation (invoice) {
      try {
        this.fetchingInvoiceInformationForInvoiceId = invoice.id
        const response = await loadBoCountInvoiceInformation(invoice.id)
        this.$emit('reloadInvoices')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan facturatiedetails niet laden')
      } finally {
        this.fetchingInvoiceInformationForInvoiceId = null
      }
    },
    async triggerManualPayment (invoice) {
      try {
        this.triggeringManualPaymentForInvoiceId = invoice.id
        const response = await markInvoicePaid(invoice.id)
        this.$emit('reloadInvoices')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan betaling niet manueel registreren')
      } finally {
        this.triggeringManualPaymentForInvoiceId = null
      }
    }
  }
}
</script>
