<template>
  <div>
    <div class="tw-my-4">
      <label class="formulate-label tw-my-1">Betaling voorschot op rekening</label>
      <FormulateInput
        type="radio"
        name="advance_payment_account"
        label="Dewaele"
        value="1"
        :disabled="soldByCoBroker"
        :input-class="['tw-my-1']"
        outer-class="tw-my-0"
        @input="advancePaymentAccountChanged"
      />
      <!-- Do not allow deducting sales fees from advance payment in case it was not made to Dewaele -->
      <FormulateInput
        type="checkbox"
        name="should_deduct_sales_fee_from_advance_payment"
        label="Afhouden ereloon van voorschot"
        :disabled="parseInt(values.advance_payment_account) !== 1 || soldByCoBroker"
        outer-class="tw-mx-2 tw-my-1"
      />
      <FormulateInput
        type="radio"
        name="advance_payment_account"
        label="Notaris/derde"
        value="2"
        :disabled="soldByCoBroker"
        :input-class="['tw-my-1']"
        outer-class="tw-my-0"
        @input="advancePaymentAccountChanged"
      />
      <FormulateInput
        v-if="isUpdate"
        type="radio"
        name="advance_payment_account"
        label="Er is geen voorschot"
        value="3"
        :input-class="['tw-my-1']"
        outer-class="tw-my-1"
        @input="advancePaymentAccountChanged"
      />
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4">
      <FormulateInput
        type="number"
        name="advance_payment"
        label="Voorschot"
        placeholder="Voorschot"
        value="0"
        lang="nl"
        step="0.01"
        min="0"
        :validation="[
          ['bail'],
          [values.sold_by !== 'co-broker' ? 'required' : ''],
          ['number'],
          ['min', 0],
          [isUpdate ? 'validAdvanceAmount' : '']
        ]"
        :validation-rules="{ validAdvanceAmount }"
        :validation-messages="{
          validAdvanceAmount: 'Het voorschot is lager dan het bedrag dat al betaald werd.'
        }"
        :show-required-label="values.sold_by !== 'co-broker'"
        :disabled="soldByCoBroker"
        data-lpignore="true"
        outer-class="tw-my-1"
      />
      <FormulateInput
        v-show="parseInt(values.advance_payment_account) === 1 && !soldByCoBroker"
        type="select"
        name="third_party_account"
        label="Derdenrekening"
        placeholder="Derdenrekening"
        :validation="parseInt(values.advance_payment_account) === 1 && !soldByCoBroker ? 'required' : ''"
        :options="thirdPartyAccounts"
        :disabled="thirdPartyAccountInputIsDisabled"
        outer-class="tw-my-0 tw-col-span-2"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SalesAdvanceForm',
  props: {
    values: {
      type: Object,
      required: true
    },
    soldByCoBroker: {
      type: Boolean,
      required: true
    },
    thirdPartyAccounts: {
      type: Array,
      required: true
    },
    advance: {
      type: Object,
      required: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    thirdPartyAccountInputIsDisabled () {
      return this.thirdPartyAccounts.length <= 1 || (this.advance?.amount !== this.advance?.balance && this.isUpdate)
    }
  },
  methods: {
    advancePaymentAccountChanged (value) {
      this.$emit('advance-payment-account-changed', value)
    },
    validAdvanceAmount ({ value }) {
      // New amount should always be greater than or equal to what was already paid of the advance
      // Current amount: 5000
      // Balance: 2000 (3000 already paid)
      // New amount: 3000
      // 3000 - (5000 - 2000) = 0 -> Valid
      // New amount: 2500
      // 2500 - (5000 - 2000) = -500 -> Invalid
      // New amount: 6000
      // 6000 - (5000 - 2000) = 3000 -> Valid
      return value - (this.advance.amount - this.advance.balance) >= 0
    }
  }
}
</script>
