import { httpClient } from './axiosClients'

export function getEntityFileTypes ({ params }) {
  return httpClient.get('/api/v3/entity-file-types', { params })
}

export function getPropertyInfoSheets (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/info-sheets`)
}

export function getProjectInfoSheets (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/info-sheets`)
}

export function bulkUpdatePropertyInfoSheets (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/info-sheets/bulk-update`, payload)
}

export function bulkUpdateProjectInfoSheets (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/info-sheets/bulk-update`, payload)
}
