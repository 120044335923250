<template>
  <BaseModal ref='modal' title="Voorschot bewerken">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="updateSalesAdvanceForm"
      @submit="submit"
    >
      <div class="tw-my-4">
        <FormulateInput
          type="checkbox"
          name="delete_advance"
          label="Dewaele verwacht geen gelden op de derdenrekening voor intrede"
          outer-class="tw-my-1"
        />
      </div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="number"
          name="advance_payment"
          label="Voorschot"
          placeholder="Voorschot"
          value="0"
          lang="nl"
          step="0.01"
          min="0"
          :validation="[
            ['bail'],
            ['number'],
            ['min', 0],
            ['validAdvanceAmount']
          ]"
          :validation-rules="{ validAdvanceAmount }"
          :validation-messages="{
            validAdvanceAmount: 'Het voorschot is lager dan het bedrag dat al betaald werd.'
          }"
          data-lpignore="true"
          outer-class="tw-my-1"
        />
        <FormulateInput
          type="select"
          name="third_party_account"
          label="Derdenrekening"
          placeholder="Derdenrekening"
          :validation="parseInt(values.advance_payment_account) === 1 && !soldByCoBroker ? 'required' : ''"
          :options="thirdPartyAccounts"
          :disabled="thirdPartyAccountInputIsDisabled"
          outer-class="tw-my-1 tw-col-span-2"
        />
      </div>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal, questionModal } from '@/modalMessages'
import { updateAdvance, deleteAdvance } from '@/services/transactions'

export default {
  name: 'UpdateRentalAdvanceModal',
  props: {
    thirdPartyAccounts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      advance: {},
      values: {}
    }
  },
  computed: {
    thirdPartyAccountInputIsDisabled () {
      return this.thirdPartyAccounts.length <= 1 || (this.advance?.amount !== this.advance?.balance && this.isUpdate)
    }
  },
  methods: {
    show (advance) {
      this.advance = advance
      this.values = {
        delete_advance: false,
        advance_payment: advance.amount,
        third_party_account: advance.account
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async updateAdvance (data) {
      const payload = {
        account: data.third_party_account,
        amount: data.advance_payment
      }
      const response = await updateAdvance(this.advance.id, payload)
      return response
    },
    async submit (data) {
      try {
        let updateResponse = null
        let deleteResponse = null
        if (!this.values.delete_advance) {
          updateResponse = await this.updateAdvance(data)
        } else {
          if (this.advance.amount !== this.advance.balance) {
            // This means part of the advance was already paid to a third party account
            const result = await questionModal(
              'Er werd al een deel van het voorschot betaald op een derdenrekening. ' +
              'Weet je zeker dat je het voorschot wilt verwijderen?'
            )
            if (!result.value) return
          }
          deleteResponse = await deleteAdvance(this.advance.id)
        }
        this.hide()
        successModal('Het voorschot werd aangepast.')
        this.$emit('updated', this.advance.property)
        return { updateResponse, deleteResponse }
      } catch (error) {
        this.$formulate.handle(error, 'updateRentalAdvanceForm')
      }
    },
    validAdvanceAmount ({ value }) {
      // New amount should always be greater than or equal to what was already paid of the advance
      // Current amount: 5000
      // Balance: 2000 (3000 already paid)
      // New amount: 3000
      // 3000 - (5000 - 2000) = 0 -> Valid
      // New amount: 2500
      // 2500 - (5000 - 2000) = -500 -> Invalid
      // New amount: 6000
      // 6000 - (5000 - 2000) = 3000 -> Valid
      return value - (this.advance.amount - this.advance.balance) >= 0
    }
  }
}
</script>
